import React, { Component, useState, useEffect, useContext, forwardRef } from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Table,
} from "react-bootstrap";
import "datatables.net-dt/js/dataTables.dataTables.js";
import DatePicker from "react-datepicker";
import "datatables.net-responsive-dt";
import { parse, format } from 'date-fns';
import { IoIosHelpCircle } from "react-icons/io";
import Tooltip from '../tooltip/Tooltip';
import RecentItems from "../recentItems/RecentItems";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import { AES, enc } from 'crypto-js';
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Header from "../Header";
import Footer from "../Footer";
import { API_URL } from '../config';
import {
  FaEnvelope,
  FaPhoneSquareAlt,
  FaMobileAlt,
  FaFax,
} from "react-icons/fa";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import $ from "jquery";
import { useParams } from "react-router-dom";
const ProductDetail = () => {
  // const params = useParams();
  // const id = params.id;
  const {id} = useParams()
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;

  const [isSubmitted, setIsSubmitted] = useState(false);
  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username, token } = useContext(TokenContext);
  const [quotes, setQuotes] = useState({});
  const [quotesProduct, setQuotesProduct] = useState([]);
  const [product, setProduct] = useState([]);
  const [buyer, setBuyer] = useState([]);
  const [history, setHistory] = useState([]);
  const [productCode, setProductCode] = useState("");
  const [productInfo, setProductInfo] = useState([]);
  const [productValues, setProductValues] = useState([]);
  const [inquiryDate, setInquiry] = useState('');
  const [closeDate, setCloseDate] = useState('');



  const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US", options).format(number);
  };

  const renderShipmentQty = (tcfValues) => {
    if (tcfValues > 0) {
      return <span className='sr'>{formatNumber(tcfValues)}</span>;
    } else if (tcfValues < 0) {
      return <span className='sr'>({formatNumber(Math.abs(tcfValues))})</span>;
    } else {
      return <span className='sr'>{formatNumber(tcfValues)}</span>;
    }
  };


  const[contractShippment, setContractShippment]=useState([])
  useEffect(() => {
    fetchContract();
  }, [decryptedAccessToken]);

  const fetchContract = async () => {
    try {
      const response = await axios.get(API_URL+'get/allcontract_shipments', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.contractshipment;
      console.log(data)
      setContractShippment(data);

    } catch (error) {
      console.error(error);
    }
  };




  const [formData, setFormData] = useState({
    locked:"",
quotes_status:"",
quotes_name:"",
no_of_product:"",
no_of_quote_shipment:"",
quote_shipment:"",
product_code:"",
product_name:"",
product_account:"",
supplier:"",
product_description:"",
product_family:"",
product_shipping_term:"",
product_owner:"",
product_active:"",
short_term_available:"",
product_price_basis:"",
price_type:"",
port_of_loading:"",
total_moisture_typical:"",
total_moisture_minimum:"",
total_moisture_maximum:"",
moisture_maximum:"",
ash_typical_arb:"",
ash_minimum_arb:"",
ash_maximum_arb:"",
sulphur_typical_arb:"",
sulphur_minimum_arb:"",
sulphur_maximum_arb:"",
volatile_matter_typical_arb:"",
volatile_matter_minimum_arb:"",
volatile_matter_maximum_arb:"",

moisture_typical:"",
moisture_minimum:"",
ash_typical:"",
ash_minimum:"",
ash_maximum:"",

volatile_matter_typical:"",
volatile_matter_minimum:"",
volatile_matter_maximum:"",
fixed_carbon_by_difference:"",
fixed_carbon_typical:"",
fixed_carbon_minimum:"",
fixed_carbon_maximum:"",

gross_air_dried_typical:"",
gross_air_dried_min:"",
gross_air_dried_max:"",
gross_as_received_min:"",
gross_as_received_max:"",
net_as_received_min:"",
net_as_received_max:"",

hgi_typical:"",
hgi_min:"",
hgi_max:"",

carbon_typical:"",
carbon_min:"",
carbon_max:"",

hydrogen_typical:"",
hydrogen_min:"",
hydrogen_max:"",

nitrogen_typical:"",
nitrogen_min:"",
nitrogen_max:"",

sulphur_typical:"",
sulphur_min:"",
sulphur_max_ultimate_analysis:"",
oxygen_typical:"",
oxygen_min:"",
oxygen_max:"",

sulphur_typical_adb:"",
sulphur_min_adb:"",
product_sulphur_max:"",

initial_typical:"",
initial_minimum:"",
initial_maximum:"",
spherical_typical:"",
spherical_minimum:"",
spherical_maximum:"",
hemispherical_typical:"",
hemispherical_minimum:"",
hemispherical_maximum:"",
flow_typical:"",
flow_minimum:"",
flow_maximum:"",

oxidising_initial_typical:"",
oxidising_initial_minimum:"",
oxidising_initial_maximum:"",
oxidising_spherical_typical:"",
oxidising_spherical_minimum:"",
oxidising_spherical_maximum:"",
oxidising_hemispherical_typical:"",
oxidising_hemispherical_minimum:"",
oxidising_hemispherical_maximum:"",
oxidising_flow_typical:"",
oxidising_flow_minimum:"",
oxidising_flow_maximum:"",

SiO2_Typical:"",
SiO2_Minimum:"",
SiO2_Maximum:"",
Al2O3_Typical:"",
Al2O3_Minimum:"",
Al2O3_Maximum:"",
Fe2O3_Typical:"",
Fe2O3_Minimum:"",
Fe2O3_Maximum:"",
CaO_Typical:"",
CaO_Minimum:"",
CaO_Maximum:"",
MgO_Typical:"",
MgO_Minimum:"",
MgO_Maximum:"",
MnO_Typical:"",
MnO_Minimum:"",
MnO_Maximum:"",
TiO2_Typical:"",
TiO2_Minimum:"",
TiO2_Maximum:"",
Na2O_Typical:"",
Na2O_Minimum:"",
Na2O_Maximum:"",
K2O_Typical:"",
K2O_Minimum:"",
K2O_Maximum:"",
P2O5_Typical:"",
P2O5_Minimum:"",
P2O5_Maximum:"",
SO3_Typical:"",
SO3_Minimum:"",
SO3_Maximum:"",
te_arsenic_as_typical_in_air_dried_coal:"",
te_arsenic_as_minimum_in_air_dried_coal:"",
te_arsenic_as_maximum_in_air_dried_coal:"",
te_antimony_sb_typical_in_air_dried_coal:"",
te_antimony_sb_minimum_in_air_dried_coal:"",
te_antimony_sb_maximum_in_air_dried_coal:"",
te_beryllium_be_typical_in_air_dried_coal:"",
te_beryllium_be_minimum_in_air_dried_coal:"",
te_beryllium_be_maximum_in_air_dried_coal:"",
te_boron_b_typical_in_air_dried_coal:"",
te_boron_b_minimum_in_air_dried_coal:"",
te_boron_b_maximum_in_air_dried_coal:"",
te_cadmium_cd_typical_in_air_dried_coal:"",
te_cadmium_cd_minimum_in_air_dried_coal:"",
te_cadmium_cd_maximum_in_air_dried_coal:"",
te_chromium_cr_typical_in_air_dried_coal:"",
te_chromium_cr_minimum_in_air_dried_coal:"",
te_chromium_cr_maximum_in_air_dried_coal:"",
te_copper_cu_typical_in_air_dried_coal:"",
te_copper_cu_minimum_in_air_dried_coal:"",
te_copper_cu_maximum_in_air_dried_coal:"",
te_chlorine_cl_typical_in_air_dried_coal:"",
te_chlorine_cl_minimum_in_air_dried_coal:"",
te_chlorine_cl_maximum_in_air_dried_coal:"",
te_fluorine_f_typical_in_air_dried_coal:"",
te_fluorine_f_minimum_in_air_dried_coal:"",
te_fluorine_f_maximum_in_air_dried_coal:"",
te_mercury_hg_typical_in_air_dried_coal:"",
te_mercury_hg_minimum_in_air_dried_coal:"",
te_mercury_hg_maximum_in_air_dried_coal:"",
te_molybdenum_mo_typical_in_air_dried_coal:"",
te_molybdenum_mo_minimum_in_air_dried_coal:"",
te_molybdenum_mo_maximum_in_air_dried_coal:"",
te_lead_pb_typical_in_air_dried_coal:"",
te_lead_pb_minimum_in_air_dried_coal:"",
te_lead_pb_maximum_in_air_dried_coal:"",
te_phosphorus_p_typical_in_air_dried_coal:"",
te_phosphorus_p_minimum_in_air_dried_coal:"",
te_phosphorus_p_maximum_in_air_dried_coal:"",
te_selenium_se_typical_in_air_dried_coal:"",
te_selenium_se_minimum_in_air_dried_coal:"",
te_selenium_se_maximum_in_air_dried_coal:"",
te_vanadium_v_typical_in_air_dried_coal:"",
te_vanadium_v_minimum_in_air_dried_coal:"",
te_vanadium_v_maximum_in_air_dried_coal:"",
te_zinc_zn_typical_in_air_dried_coal:"",
te_zinc_zn_minimum_in_air_dried_coal:"",
te_zinc_zn_maximum_in_air_dried_coal:"",

te_arsenic_as_typical:"",
te_arsenic_as_minimum:"",
te_arsenic_as_maximum:"",
te_antimony_sb_typical:"",
te_antimony_sb_minimum:"",
te_antimony_sb_maximum:"",
te_boron_b_typical:"",
te_boron_b_minimum:"",
te_boron_b_maximum:"",
te_cadmium_cd_typical:"",
te_cadmium_cd_minimum:"",
te_cadmium_cd_maximum:"",
te_chromium_cr_typical:"",
te_chromium_cr_minimum:"",
te_chromium_cr_maximum:"",
te_copper_cu_typical:"",
te_copper_cu_minimum:"",
te_copper_cu_maximum:"",
te_chlorine_cl_typical:"",
te_chlorine_cl_minimum:"",
te_chlorine_cl_maximum:"",
te_fluorine_f_typical:"",
te_fluorine_f_minimum:"",
te_fluorine_f_maximum:"",
te_mercury_hg_typical:"",
te_mercury_hg_minimum:"",
te_mercury_hg_maximum:"",
te_molybdenum_mo_typical:"",
te_molybdenum_mo_minimum:"",
te_molybdenum_mo_maximum:"",
te_lead_pb_typical:"",
te_lead_pb_minimum:"",
te_lead_pb_maximum:"",
te_phosphorus_p_typical:"",
te_phosphorus_p_minimum:"",
te_phosphorus_p_maximum:"",
te_selenium_se_typical:"",
te_selenium_se_minimum:"",
te_selenium_se_maximum:"",
te_vanadium_v_typical:"",
laycan_from_date:"",
laycan_to_date:"",
te_vanadium_v_min:"",
te_vanadium_v_max:"",
te_zinc_zn_typical:"",
te_zinc_zn_min:"",
te_zinc_zn_max:"",
product_master:"",
size_0_to_50mm_typical:"",
size_0_to_50mm_min:"",
size_0_to_50mm_max:"",
above_0_to_50mm_typical:"",
above_0_to_50mm_min:"",
above_0_to_50mm_max:"",
under_2mm_typical:"",
under_2mm_min:"",
under_2mm_max:"",
size_above_2mm_typical:"",
size_above_2mm_min:"",
size_above_2mm_max:"",
quantity_MT:"",
product_status:"",
product_year:"",
shipping_term:"",
vessel_type:"",
testing_method:""
  });
  const getProductValues = async (productCode) => {
    try {
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);
        const response = await axios.get(
          `${API_URL}all/product/datas/product_code/${productCode}`,
          {
            headers: { Authorization: `Bearer ${decryptedAccessToken}` },
          }
        );
console.log(response.data)
        setProductValues(response.data);

        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  };


  const[contracts, setContracts]=useState([])
  useEffect(() => {

    fetchDataContract();
  }, [decryptedAccessToken]);

  const fetchDataContract = async () => {
    try {
      const response = await axios.get(API_URL+'get/contract_shipbyID/'+id, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data;
      setContracts(data.contract_details[0]);
      console.log(response)

    } catch (error) {
      console.error(error);
    }
  };

  const [productApend, setProductcode] = useState('');
  function changeStatus(e) {
    setProductcode(e.target.value);
  }

 const dropdownChange = e => {
    changeStatus(e)
    handleProductCodeChange(e)
    handleChange(e)
  }
  const [products, setproducts] = useState([]);

  useEffect(() => {
    const fetchProductCodes = async () => {
      try {
        const encryptedAccessToken = localStorage.getItem("access_token");
        const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(
            encryptedAccessToken,
            secret_key
          ).toString(enc.Utf8);
          const response = await axios.get(
            API_URL+"get/products_api",
            {
              headers: { Authorization: `Bearer ${decryptedAccessToken}` },
            }
          );



          setproducts(response.data);

        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchProductCodes();

  }, []);

  const [puchaseContract, setPurchasecontract]=useState([]);
  useEffect(() => {
    fetchContractLists();
  }, [decryptedAccessToken]);

  const fetchContractLists= async () => {
    try {
      const response = await axios.get(API_URL+'get/all/purchase_contract', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.purchase_contract;
      console.log(data)
      setPurchasecontract(data);

    } catch (error) {
      console.error(error);
    }
  };




  const [datasContract, setdatasContract]=useState([]);
  useEffect(() => {

    fetchContractList();
  }, [decryptedAccessToken]);

  const fetchContractList = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/contract', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.contract;
      console.log(data)
      setdatasContract(data);

    } catch (error) {
      console.error(error);
    }
  };


  const handleProductCodeChange = async (event) => {
    setProductCode(event.target.value);
    try {
      const productValues = await getProductValues(event.target.value);
      console.log(productValues);

      setFormData({
        ...formData,
        product_code: event.target.value,
        locked:productValues.locked,
product_master:productValues.product_code,
product_code:productValues.product_code,
product_name:productValues.product_name,
product_account:productValues.product_account,
supplier:productValues.supplier,
product_description:productValues.product_description,
product_family:productValues.product_family,
product_shipping_term:productValues.product_shipping_term,
product_owner:productValues.product_owner,
product_active:productValues.product_active,
short_term_available:productValues.short_term_available,
product_price_basis:productValues.product_price_basis,
price_type:productValues.price_type,
port_of_loading:productValues.port_of_loading,
total_moisture_typical:productValues.total_moisture_typical,
total_moisture_minimum:productValues.total_moisture_minimum,
total_moisture_maximum:productValues.total_moisture_maximum,
moisture_maximum:productValues.moisture_maximum,
ash_typical_arb:productValues.ash_typical_arb,
ash_minimum_arb:productValues.ash_minimum_arb,
ash_maximum_arb:productValues.ash_maximum_arb,
sulphur_typical_arb:productValues.sulphur_typical_arb,
sulphur_minimum_arb:productValues.sulphur_minimum_arb,
sulphur_maximum_arb:productValues.sulphur_maximum_arb,
volatile_matter_typical_arb:productValues.volatile_matter_typical_arb,
volatile_matter_minimum_arb:productValues.volatile_matter_minimum_arb,
volatile_matter_maximum_arb:productValues.volatile_matter_maximum_arb,

moisture_typical:productValues.moisture_typical,
moisture_minimum:productValues.moisture_minimum,
ash_typical:productValues.ash_typical,
ash_minimum:productValues.ash_minimum,
ash_maximum:productValues.ash_maximum,

volatile_matter_typical:productValues.volatile_matter_typical,
volatile_matter_minimum:productValues.volatile_matter_minimum,
volatile_matter_maximum:productValues.volatile_matter_maximum,
fixed_carbon_by_difference:productValues.fixed_carbon_by_difference,
fixed_carbon_typical:productValues.fixed_carbon_typical,
fixed_carbon_minimum:productValues.fixed_carbon_minimum,
fixed_carbon_maximum:productValues.fixed_carbon_maximum,
quantity_MT:productValues.quantity_MT,
gross_air_dried_typical:productValues.gross_air_dried_typical,
gross_air_dried_min:productValues.gross_air_dried_min,
gross_air_dried_max:productValues.gross_air_dried_max,
gross_as_received_min:productValues.gross_as_received_min,
gross_as_received_max:productValues.gross_as_received_max,
net_as_received_min:productValues.net_as_received_min,
net_as_received_max:productValues.net_as_received_max,

hgi_typical:productValues.hgi_typical,
hgi_min:productValues.hgi_min,
hgi_max:productValues.hgi_max,

carbon_typical:productValues.carbon_typical,
carbon_min:productValues.carbon_min,
carbon_max:productValues.carbon_max,

hydrogen_typical:productValues.hydrogen_typical,
hydrogen_min:productValues.hydrogen_min,
hydrogen_max:productValues.hydrogen_max,

nitrogen_typical:productValues.nitrogen_typical,
nitrogen_min:productValues.nitrogen_min,
nitrogen_max:productValues.nitrogen_max,

sulphur_typical:productValues.sulphur_typical,
sulphur_min:productValues.sulphur_min,
sulphur_max_ultimate_analysis:productValues.sulphur_max_ultimate_analysis,
oxygen_typical:productValues.oxygen_typical,
oxygen_min:productValues.oxygen_min,
oxygen_max:productValues.oxygen_max,

sulphur_typical_adb:productValues.sulphur_typical_adb,
sulphur_min_adb:productValues.sulphur_min_adb,
product_sulphur_max:productValues.product_sulphur_max,

initial_typical:productValues.initial_typical,
initial_minimum:productValues.initial_minimum,
initial_maximum:productValues.initial_maximum,
spherical_typical:productValues.spherical_typical,
spherical_minimum:productValues.spherical_minimum,
spherical_maximum:productValues.spherical_maximum,
hemispherical_typical:productValues.hemispherical_typical,
hemispherical_minimum:productValues.hemispherical_minimum,
hemispherical_maximum:productValues.hemispherical_maximum,
flow_typical:productValues.flow_typical,
flow_minimum:productValues.flow_minimum,
flow_maximum:productValues.flow_maximum,

oxidising_initial_typical:productValues.oxidising_initial_typical,
oxidising_initial_minimum:productValues.oxidising_initial_minimum,
oxidising_initial_maximum:productValues.oxidising_initial_maximum,
oxidising_spherical_typical:productValues.oxidising_spherical_typical,
oxidising_spherical_minimum:productValues.oxidising_spherical_minimum,
oxidising_spherical_maximum:productValues.oxidising_spherical_maximum,
oxidising_hemispherical_typical:productValues.oxidising_hemispherical_typical,
oxidising_hemispherical_minimum:productValues.oxidising_hemispherical_minimum,
oxidising_hemispherical_maximum:productValues.oxidising_hemispherical_maximum,
oxidising_flow_typical:productValues.oxidising_flow_typical,
oxidising_flow_minimum:productValues.oxidising_flow_typical,
oxidising_flow_maximum:productValues.oxidising_flow_maximum,

SiO2_Typical:productValues.SiO2_Typical,
SiO2_Minimum:productValues.SiO2_Minimum,
SiO2_Maximum:productValues.SiO2_Maximum,
Al2O3_Typical:productValues.Al2O3_Typical,
Al2O3_Minimum:productValues.Al2O3_Minimum,
Al2O3_Maximum:productValues.Al2O3_Maximum,
Fe2O3_Typical:productValues.Fe2O3_Typical,
Fe2O3_Minimum:productValues.Fe2O3_Minimum,
Fe2O3_Maximum:productValues.Fe2O3_Maximum,
CaO_Typical:productValues.CaO_Typical,
CaO_Minimum:productValues.CaO_Minimum,
CaO_Maximum:productValues.CaO_Maximum,
MgO_Typical:productValues.MgO_Typical,
MgO_Minimum:productValues.MgO_Minimum,
MgO_Maximum:productValues.MgO_Maximum,
MnO_Typical:productValues.MnO_Typical,
MnO_Minimum:productValues.MnO_Minimum,
MnO_Maximum:productValues.MnO_Maximum,
TiO2_Typical:productValues.TiO2_Typical,
TiO2_Minimum:productValues.TiO2_Minimum,
TiO2_Maximum:productValues.TiO2_Maximum,
Na2O_Typical:productValues.Na2O_Typical,
Na2O_Minimum:productValues.Na2O_Minimum,
Na2O_Maximum:productValues.Na2O_Maximum,
K2O_Typical:productValues.K2O_Typical,
K2O_Minimum:productValues.K2O_Minimum,
K2O_Maximum:productValues.K2O_Maximum,
P2O5_Typical:productValues.P2O5_Typical,
P2O5_Minimum:productValues.P2O5_Minimum,
P2O5_Maximum:productValues.P2O5_Maximum,
SO3_Typical:productValues.SO3_Typical,
SO3_Minimum:productValues.SO3_Minimum,
SO3_Maximum:productValues.SO3_Maximum,
te_arsenic_as_typical_in_air_dried_coal:productValues.te_arsenic_as_typical_in_air_dried_coal,
te_arsenic_as_minimum_in_air_dried_coal:productValues.te_arsenic_as_minimum_in_air_dried_coal,
te_arsenic_as_maximum_in_air_dried_coal:productValues.te_arsenic_as_maximum_in_air_dried_coal,
te_antimony_sb_typical_in_air_dried_coal:productValues.te_antimony_sb_typical_in_air_dried_coal,
te_antimony_sb_minimum_in_air_dried_coal:productValues.te_antimony_sb_minimum_in_air_dried_coal,
te_antimony_sb_maximum_in_air_dried_coal:productValues.te_antimony_sb_maximum_in_air_dried_coal,
te_beryllium_be_typical_in_air_dried_coal:productValues.te_beryllium_be_typical_in_air_dried_coal,
te_beryllium_be_minimum_in_air_dried_coal:productValues.te_beryllium_be_minimum_in_air_dried_coal,
te_beryllium_be_maximum_in_air_dried_coal:productValues.te_beryllium_be_maximum_in_air_dried_coal,
te_boron_b_typical_in_air_dried_coal:productValues.te_boron_b_typical_in_air_dried_coal,
te_boron_b_minimum_in_air_dried_coal:productValues.te_boron_b_minimum_in_air_dried_coal,
te_boron_b_maximum_in_air_dried_coal:productValues.te_boron_b_maximum_in_air_dried_coal,
te_cadmium_cd_typical_in_air_dried_coal:productValues.te_cadmium_cd_typical_in_air_dried_coal,
te_cadmium_cd_minimum_in_air_dried_coal:productValues.te_cadmium_cd_minimum_in_air_dried_coal,
te_cadmium_cd_maximum_in_air_dried_coal:productValues.te_cadmium_cd_maximum_in_air_dried_coal,
te_chromium_cr_typical_in_air_dried_coal:productValues.te_chromium_cr_typical_in_air_dried_coal,
te_chromium_cr_minimum_in_air_dried_coal:productValues.te_chromium_cr_minimum_in_air_dried_coal,
te_chromium_cr_maximum_in_air_dried_coal:productValues.te_chromium_cr_maximum_in_air_dried_coal,
te_copper_cu_typical_in_air_dried_coal:productValues.te_copper_cu_typical_in_air_dried_coal,
te_copper_cu_minimum_in_air_dried_coal:productValues.te_copper_cu_minimum_in_air_dried_coal,
te_copper_cu_maximum_in_air_dried_coal:productValues.te_copper_cu_maximum_in_air_dried_coal,
te_chlorine_cl_typical_in_air_dried_coal:productValues.te_chlorine_cl_typical_in_air_dried_coal,
te_chlorine_cl_minimum_in_air_dried_coal:productValues.te_chlorine_cl_minimum_in_air_dried_coal,
te_chlorine_cl_maximum_in_air_dried_coal:productValues.te_chlorine_cl_maximum_in_air_dried_coal,
te_fluorine_f_typical_in_air_dried_coal:productValues.te_fluorine_f_typical_in_air_dried_coal,
te_fluorine_f_minimum_in_air_dried_coal:productValues.te_fluorine_f_minimum_in_air_dried_coal,
te_fluorine_f_maximum_in_air_dried_coal:productValues.te_fluorine_f_maximum_in_air_dried_coal,
te_mercury_hg_typical_in_air_dried_coal:productValues.te_mercury_hg_typical_in_air_dried_coal,
te_mercury_hg_minimum_in_air_dried_coal:productValues.te_mercury_hg_minimum_in_air_dried_coal,
te_mercury_hg_maximum_in_air_dried_coal:productValues.te_mercury_hg_maximum_in_air_dried_coal,
te_molybdenum_mo_typical_in_air_dried_coal:productValues.te_molybdenum_mo_typical_in_air_dried_coal,
te_molybdenum_mo_minimum_in_air_dried_coal:productValues.te_molybdenum_mo_minimum_in_air_dried_coal,
te_molybdenum_mo_maximum_in_air_dried_coal:productValues.te_molybdenum_mo_maximum_in_air_dried_coal,
te_lead_pb_typical_in_air_dried_coal:productValues.te_lead_pb_typical_in_air_dried_coal,
te_lead_pb_minimum_in_air_dried_coal:productValues.te_lead_pb_minimum_in_air_dried_coal,
te_lead_pb_maximum_in_air_dried_coal:productValues.te_lead_pb_maximum_in_air_dried_coal,
te_phosphorus_p_typical_in_air_dried_coal:productValues.te_phosphorus_p_typical_in_air_dried_coal,
te_phosphorus_p_minimum_in_air_dried_coal:productValues.te_phosphorus_p_minimum_in_air_dried_coal,
te_phosphorus_p_maximum_in_air_dried_coal:productValues.te_phosphorus_p_maximum_in_air_dried_coal,
te_selenium_se_typical_in_air_dried_coal:productValues.te_selenium_se_typical_in_air_dried_coal,
te_selenium_se_minimum_in_air_dried_coal:productValues.te_selenium_se_minimum_in_air_dried_coal,
te_selenium_se_maximum_in_air_dried_coal:productValues.te_selenium_se_maximum_in_air_dried_coal,
te_vanadium_v_typical_in_air_dried_coal:productValues.te_vanadium_v_typical_in_air_dried_coal,
te_vanadium_v_minimum_in_air_dried_coal:productValues.te_vanadium_v_minimum_in_air_dried_coal,
te_vanadium_v_maximum_in_air_dried_coal:productValues.te_vanadium_v_maximum_in_air_dried_coal,
te_zinc_zn_typical_in_air_dried_coal:productValues.te_zinc_zn_typical_in_air_dried_coal,
te_zinc_zn_minimum_in_air_dried_coal:productValues.te_zinc_zn_minimum_in_air_dried_coal,
te_zinc_zn_maximum_in_air_dried_coal:productValues.te_zinc_zn_maximum_in_air_dried_coal,
product_month:productValues.product_month,
te_arsenic_as_typical:productValues.te_arsenic_as_typical,
te_arsenic_as_minimum:productValues.te_arsenic_as_minimum,
te_arsenic_as_maximum:productValues.te_arsenic_as_maximum,
te_antimony_sb_typical:productValues.te_antimony_sb_typical,
te_antimony_sb_minimum:productValues.te_antimony_sb_minimum,
te_antimony_sb_maximum:productValues.te_antimony_sb_maximum,
te_boron_b_typical:productValues.te_boron_b_typical,
te_boron_b_minimum:productValues.te_boron_b_minimum,
te_boron_b_maximum:productValues.te_boron_b_maximum,
te_cadmium_cd_typical:productValues.te_cadmium_cd_typical,
te_cadmium_cd_minimum:productValues.te_cadmium_cd_minimum,
te_cadmium_cd_maximum:productValues.te_cadmium_cd_maximum,
te_chromium_cr_typical:productValues.te_chromium_cr_typical,
te_chromium_cr_minimum:productValues.te_chromium_cr_minimum,
te_chromium_cr_maximum:productValues.te_chromium_cr_maximum,
te_copper_cu_typical:productValues.te_copper_cu_typical,
te_copper_cu_minimum:productValues.te_copper_cu_minimum,
te_copper_cu_maximum:productValues.te_copper_cu_maximum,
te_chlorine_cl_typical:productValues.te_chlorine_cl_typical,
te_chlorine_cl_minimum:productValues.te_chlorine_cl_minimum,
te_chlorine_cl_maximum:productValues.te_chlorine_cl_maximum,
te_fluorine_f_typical:productValues.te_fluorine_f_typical,
te_fluorine_f_minimum:productValues.te_fluorine_f_minimum,
te_fluorine_f_maximum:productValues.te_fluorine_f_maximum,
te_mercury_hg_typical:productValues.te_mercury_hg_typical,
te_mercury_hg_minimum:productValues.te_mercury_hg_minimum,
te_mercury_hg_maximum:productValues.te_mercury_hg_maximum,
te_molybdenum_mo_typical:productValues.te_molybdenum_mo_typical,
te_molybdenum_mo_minimum:productValues.te_molybdenum_mo_minimum,
te_molybdenum_mo_maximum:productValues.te_molybdenum_mo_maximum,
te_lead_pb_typical:productValues.te_lead_pb_typical,
te_lead_pb_minimum:productValues.te_lead_pb_minimum,
te_lead_pb_maximum:productValues.te_lead_pb_maximum,
te_phosphorus_p_typical:productValues.te_phosphorus_p_typical,
te_phosphorus_p_minimum:productValues.te_phosphorus_p_minimum,
te_phosphorus_p_maximum:productValues.te_phosphorus_p_maximum,
te_selenium_se_typical:productValues.te_selenium_se_typical,
te_selenium_se_minimum:productValues.te_selenium_se_minimum,
te_selenium_se_maximum:productValues.te_selenium_se_maximum,
te_vanadium_v_typical:productValues.te_vanadium_v_typical,
te_vanadium_v_min:productValues.te_vanadium_v_min,
te_vanadium_v_max:productValues.te_vanadium_v_max,
te_zinc_zn_typical:productValues.te_zinc_zn_typical,
te_zinc_zn_min:productValues.te_zinc_zn_min,
te_zinc_zn_max:productValues.te_zinc_zn_max,

size_0_to_50mm_typical:productValues.size_0_to_50mm_typical,
size_0_to_50mm_min:productValues.size_0_to_50mm_min,
size_0_to_50mm_max:productValues.size_0_to_50mm_max,
above_0_to_50mm_typical:productValues.above_0_to_50mm_typical,
above_0_to_50mm_min:productValues.above_0_to_50mm_min,
above_0_to_50mm_max:productValues.above_0_to_50mm_max,
under_2mm_typical:productValues.under_2mm_typical,
under_2mm_min:productValues.under_2mm_typical,
under_2mm_max:productValues.under_2mm_max,
size_above_2mm_typical:productValues.size_above_2mm_typical,
size_above_2mm_min:productValues.size_above_2mm_min,
size_above_2mm_max:productValues.size_above_2mm_max,
product_status:productValues.product_status,
product_year:productValues.product_year,
shipping_term:productValues.shipping_term,
vessel_type:productValues.vessel_type,
laycan_from_date:format(inquiryDate, 'dd/MM/yyyy'),
laycan_to_date:format(closeDate, 'dd/MM/yyyy'),
buyer_name:productValues.buyer_name,
testing_method:productValues.testing_method,
        ...productValues,
      });
    } catch (error) {
      console.log(error);
    }
  };



  useEffect(() => {

    fetchData1();
  }, [decryptedAccessToken]);

  const fetchData1 = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/buyer_product', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}`, 'Content-Type': 'application/json'  },        }
      );
      const data = response.data;
      console.log(data)
      // setDatas(data);
      $(document).ready(function(){
        // $('#tables').DataTable().destroy();
        // setTimeout(()=>{
          $('#tables').DataTable();
          $('#tcf').DataTable();
          $('#standard').DataTable();
          $('#buyer').DataTable();
          $('#price').DataTable();
          $('#history').DataTable();
        // },500)
       })
    } catch (error) {
      console.error(error);
    }
  };

  const [productAccount, setProductaccount] = useState([]);
  const [getPrice, setgetPrice] = useState([]);
    // product account
    useEffect(() => {
      fetchDataAccount();
    }, [decryptedAccessToken]);

    const fetchDataAccount = async () => {
      try {
        const response = await axios.get(
          API_URL+"all/accounts",
          {
            headers: { Authorization: `Bearer ${decryptedAccessToken}` },
          }
        );
        const data = response.data.accounts;
        // console.log(data)
        setProductaccount(data);
      } catch (error) {
        console.error(error);
      }
    };
  // price
  useEffect(() => {
    fetchDataPrice();
  }, [decryptedAccessToken]);

  const fetchDataPrice = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/all/price_items",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.priceitem;
      console.log(data);
      setgetPrice(data);
    } catch (error) {
      console.error(error);
    }
  };
  const [data, setData] = useState({});
  const [data1, setData1] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [surveyors, setSurveyor] = useState([]);
  const [length1, setLength1]= useState([]);
console.log(JSON.stringify(data1))
  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken, id]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/newproduct_byID/"+id,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );

      console.log(response)
      if(response.status===200){
      const data = response.data;
      setLength1(data.products_detail.length)
      setData1(data.products_detail[0]);
      setQuotes(data.products_detail[0]);
      setHistory(data.history)
      $('#tables').DataTable();
      $('#tcf').DataTable();
      $('#standard').DataTable();
      $('#buyer').DataTable();
      $('#price').DataTable();
      $('#history').DataTable();
    // }, 50);
      console.log   (data.products_detail[0].laycan_from_date);
      const inquiryDate=data[0].products_detail[0].laycan_from_date != null ? parse(data[0].products_detail[0].laycan_from_date, 'dd/MM/yyyy', new Date()) : "";
      setInquiry(inquiryDate);
      const close_date=data[0].products_detail[0].laycan_to_date != null ? parse(data[0].products_detail[0].laycan_to_date, 'dd/MM/yyyy', new Date()) : "";
      setCloseDate(close_date);
      }
      else{
         toast.error("No Data Available", {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",})
      }
    } catch (error) {
      console.error(error);
    }
  };

  const[lockedOne,setLockedOne]=useState({})
  const check_locked=data1.locked;

      useEffect(() => {
        setLockedOne(check_locked); // Update lockeds with the value of tt
      }, [check_locked]); // Run this effect whenever tt changes

      useEffect(() => {
      }, [lockedOne]); // Run this effect whenever lockeds changes



const handleCheckboxLocked = (e) => {
  setLockedOne(e.target.checked);
  // setLockeds(!lockeds)
  // Debug log

};
  const [startDate1, setStartDate1] = useState(new Date());

  const start_date=startDate1.toLocaleDateString("en-US", { year: 'numeric' })+"-"+(startDate1.toLocaleDateString("en-US", { month: '2-digit' }))+"-"+(startDate1.toLocaleDateString("en-US", { day: '2-digit' }))

 const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
   <input
     defaultValue={value}
     className="custom-input"
     onClick={onClick}
     name="birthdate"
     onChange={onChange}
     ref={ref}
   ></input>
 ));

 const [isButtonEnabled, setIsButtonEnabled] = useState(false);
 useEffect(() => {
   setIsButtonEnabled(true);
 }, []);

 const [startDate2, setStartDate2] = useState(new Date());

  const start_date2=startDate1.toLocaleDateString("en-US", { year: 'numeric' })+"-"+(startDate1.toLocaleDateString("en-US", { month: '2-digit' }))+"-"+(startDate1.toLocaleDateString("en-US", { day: '2-digit' }))

 const CustomInput2 = forwardRef(({ value, onClick, onChange }, ref) => (
   <input
     defaultValue={value}
     className="custom-input"
     onClick={onClick}
     name="birthdate"
     onChange={onChange}
     ref={ref}
   ></input>
 ));


//  datepickers

const handleInquiryChange = (date) => {
  setInquiry(date);
};

const handleCloseChange = (date) => {
  setCloseDate(date);
};
const handleEditClick = () => {
  setIsEditMode(true);
  setEditedData(data);
};

const handleChange = (e) => {
  const { name, value } = e.target;
  setData1((prevState) => ({
    ...prevState,
    [name]: value,
  }));
};
setTimeout(() => {
  $('#tables').DataTable();
  $('#tcf').DataTable();
  $('#standard').DataTable();
  $('#buyer').DataTable();
  $('#price').DataTable();
  $('#history').DataTable();
}, 50);
const handleCancelEdit = () => {
  setIsEditMode(false);
  setEditedData(data);
  setTimeout(() => {
  $('#tables').DataTable();
  $('#tcf').DataTable();
  $('#standard').DataTable();
  $('#buyer').DataTable();
  $('#price').DataTable();
  $('#history').DataTable();
}, 50);
};
  const handleSaveEdit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    const responseData = {
      "product_code": e.target.value,
"locked":lockedOne,
"product_master":data1.product_code,
"product_code":data1.product_code,
"product_name":data1.product_name,
"product_account":data1.product_account,
"supplier":data1.supplier,
"product_description":data1.product_description,
"product_family":data1.product_family,
"product_shipping_term":data1.product_shipping_term,
"product_owner":data1.product_owner,
"product_active":data1.product_active,
"short_term_available":data1.short_term_available,
"product_price_basis":data1.product_price_basis,
"product_month":data1.product_month,
"price_type":data1.price_type,
"port_of_loading":data1.port_of_loading,
"total_moisture_typical":data1.total_moisture_typical,
"total_moisture_minimum":data1.total_moisture_minimum,
"total_moisture_maximum":data1.total_moisture_maximum,
"moisture_maximum":data1.moisture_maximum,
"ash_typical_arb":data1.ash_typical_arb,
"ash_minimum_arb":data1.ash_minimum_arb,
"ash_maximum_arb":data1.ash_maximum_arb,
"sulphur_typical_arb":data1.sulphur_typical_arb,
"sulphur_minimum_arb":data1.sulphur_minimum_arb,
"sulphur_maximum_arb":data1.sulphur_maximum_arb,
"volatile_matter_typical_arb":data1.volatile_matter_typical_arb,
"volatile_matter_minimum_arb":data1.volatile_matter_minimum_arb,
"volatile_matter_maximum_arb":data1.volatile_matter_maximum_arb,
"moisture_typical":data1.moisture_typical,
"moisture_minimum":data1.moisture_minimum,
"ash_typical":data1.ash_typical,
"ash_minimum":data1.ash_minimum,
"ash_maximum":data1.ash_maximum,
"buyer_name":data1.buyer_name,
"volatile_matter_typical":data1.volatile_matter_typical,
"volatile_matter_minimum":data1.volatile_matter_minimum,
"volatile_matter_maximum":data1.volatile_matter_maximum,
"fixed_carbon_by_difference":data1.fixed_carbon_by_difference,
"fixed_carbon_typical":data1.fixed_carbon_typical,
"fixed_carbon_minimum":data1.fixed_carbon_minimum,
"fixed_carbon_maximum":data1.fixed_carbon_maximum,
"quantity_MT":data1.quantity_MT,
"gross_air_dried_typical":data1.gross_air_dried_typical,
"gross_air_dried_min":data1.gross_air_dried_min,
"gross_air_dried_max":data1.gross_air_dried_max,
"gross_as_received_min":data1.gross_as_received_min,
"gross_as_received_max":data1.gross_as_received_max,
"net_as_received_min":data1.net_as_received_min,
"net_as_received_max":data1.net_as_received_max,
"hgi_typical":data1.hgi_typical,
"hgi_min":data1.hgi_min,
"hgi_max":data1.hgi_max,
"carbon_typical":data1.carbon_typical,
"carbon_min":data1.carbon_min,
"carbon_max":data1.carbon_max,
"hydrogen_typical":data1.hydrogen_typical,
"hydrogen_min":data1.hydrogen_min,
"hydrogen_max":data1.hydrogen_max,
"nitrogen_typical":data1.nitrogen_typical,
"nitrogen_min":data1.nitrogen_min,
"nitrogen_max":data1.nitrogen_max,
"sulphur_typical":data1.sulphur_typical,
"sulphur_min":data1.sulphur_min,
"sulphur_max_ultimate_analysis":data1.sulphur_max_ultimate_analysis,
"oxygen_typical":data1.oxygen_typical,
"oxygen_min":data1.oxygen_min,
"oxygen_max":data1.oxygen_max,
"sulphur_typical_adb":data1.sulphur_typical_adb,
"sulphur_min_adb":data1.sulphur_min_adb,
"product_sulphur_max":data1.product_sulphur_max,
"initial_typical":data1.initial_typical,
"initial_minimum":data1.initial_minimum,
"initial_maximum":data1.initial_maximum,
"spherical_typical":data1.spherical_typical,
"spherical_minimum":data1.spherical_minimum,
"spherical_maximum":data1.spherical_maximum,
"hemispherical_typical":data1.hemispherical_typical,
"hemispherical_minimum":data1.hemispherical_minimum,
"hemispherical_maximum":data1.hemispherical_maximum,
"flow_typical":data1.flow_typical,
"flow_minimum":data1.flow_minimum,
"flow_maximum":data1.flow_maximum,

"oxidising_initial_typical":data1.oxidising_initial_typical,
"oxidising_initial_minimum":data1.oxidising_initial_minimum,
"oxidising_initial_maximum":data1.oxidising_initial_maximum,
"oxidising_spherical_typical":data1.oxidising_spherical_typical,
"oxidising_spherical_minimum":data1.oxidising_spherical_minimum,
"oxidising_spherical_maximum":data1.oxidising_spherical_maximum,
"oxidising_hemispherical_typical":data1.oxidising_hemispherical_typical,
"oxidising_hemispherical_minimum":data1.oxidising_hemispherical_minimum,
"oxidising_hemispherical_maximum":data1.oxidising_hemispherical_maximum,
"oxidising_flow_typical":data1.oxidising_flow_typical,
"oxidising_flow_minimum":data1.oxidising_flow_typical,
"oxidising_flow_maximum":data1.oxidising_flow_maximum,

"SiO2_Typical":data1.SiO2_Typical,
"SiO2_Minimum":data1.SiO2_Minimum,
"SiO2_Maximum":data1.SiO2_Maximum,
"Al2O3_Typical":data1.Al2O3_Typical,
"Al2O3_Minimum":data1.Al2O3_Minimum,
"Al2O3_Maximum":data1.Al2O3_Maximum,
"Fe2O3_Typical":data1.Fe2O3_Typical,
"Fe2O3_Minimum":data1.Fe2O3_Minimum,
"Fe2O3_Maximum":data1.Fe2O3_Maximum,
"CaO_Typical":data1.CaO_Typical,
"CaO_Minimum":data1.CaO_Minimum,
"CaO_Maximum":data1.CaO_Maximum,
"MgO_Typical":data1.MgO_Typical,
"MgO_Minimum":data1.MgO_Minimum,
"MgO_Maximum":data1.MgO_Maximum,
"MnO_Typical":data1.MnO_Typical,
"MnO_Minimum":data1.MnO_Minimum,
"MnO_Maximum":data1.MnO_Maximum,
"TiO2_Typical":data1.TiO2_Typical,
"TiO2_Minimum":data1.TiO2_Minimum,
"TiO2_Maximum":data1.TiO2_Maximum,
"Na2O_Typical":data1.Na2O_Typical,
"Na2O_Minimum":data1.Na2O_Minimum,
"Na2O_Maximum":data1.Na2O_Maximum,
"K2O_Typical":data1.K2O_Typical,
"K2O_Minimum":data1.K2O_Minimum,
"K2O_Maximum":data1.K2O_Maximum,
"P2O5_Typical":data1.P2O5_Typical,
"P2O5_Minimum":data1.P2O5_Minimum,
"P2O5_Maximum":data1.P2O5_Maximum,
"SO3_Typical":data1.SO3_Typical,
"SO3_Minimum":data1.SO3_Minimum,
"SO3_Maximum":data1.SO3_Maximum,
"te_arsenic_as_typical_in_air_dried_coal":data1.te_arsenic_as_typical_in_air_dried_coal,
"te_arsenic_as_minimum_in_air_dried_coal":data1.te_arsenic_as_minimum_in_air_dried_coal,
"te_arsenic_as_maximum_in_air_dried_coal":data1.te_arsenic_as_maximum_in_air_dried_coal,
"te_antimony_sb_typical_in_air_dried_coal":data1.te_antimony_sb_typical_in_air_dried_coal,
"te_antimony_sb_minimum_in_air_dried_coal":data1.te_antimony_sb_minimum_in_air_dried_coal,
"te_antimony_sb_maximum_in_air_dried_coal":data1.te_antimony_sb_maximum_in_air_dried_coal,
"te_beryllium_be_typical_in_air_dried_coal":data1.te_beryllium_be_typical_in_air_dried_coal,
"te_beryllium_be_minimum_in_air_dried_coal":data1.te_beryllium_be_minimum_in_air_dried_coal,
"te_beryllium_be_maximum_in_air_dried_coal":data1.te_beryllium_be_maximum_in_air_dried_coal,
"te_boron_b_typical_in_air_dried_coal":data1.te_boron_b_typical_in_air_dried_coal,
"te_boron_b_minimum_in_air_dried_coal":data1.te_boron_b_minimum_in_air_dried_coal,
"te_boron_b_maximum_in_air_dried_coal":data1.te_boron_b_maximum_in_air_dried_coal,
"te_cadmium_cd_typical_in_air_dried_coal":data1.te_cadmium_cd_typical_in_air_dried_coal,
"te_cadmium_cd_minimum_in_air_dried_coal":data1.te_cadmium_cd_minimum_in_air_dried_coal,
"te_cadmium_cd_maximum_in_air_dried_coal":data1.te_cadmium_cd_maximum_in_air_dried_coal,
"te_chromium_cr_typical_in_air_dried_coal":data1.te_chromium_cr_typical_in_air_dried_coal,
"te_chromium_cr_minimum_in_air_dried_coal":data1.te_chromium_cr_minimum_in_air_dried_coal,
"te_chromium_cr_maximum_in_air_dried_coal":data1.te_chromium_cr_maximum_in_air_dried_coal,
"te_copper_cu_typical_in_air_dried_coal":data1.te_copper_cu_typical_in_air_dried_coal,
"te_copper_cu_minimum_in_air_dried_coal":data1.te_copper_cu_minimum_in_air_dried_coal,
"te_copper_cu_maximum_in_air_dried_coal":data1.te_copper_cu_maximum_in_air_dried_coal,
"te_chlorine_cl_typical_in_air_dried_coal":data1.te_chlorine_cl_typical_in_air_dried_coal,
"te_chlorine_cl_minimum_in_air_dried_coal":data1.te_chlorine_cl_minimum_in_air_dried_coal,
"te_chlorine_cl_maximum_in_air_dried_coal":data1.te_chlorine_cl_maximum_in_air_dried_coal,
"te_fluorine_f_typical_in_air_dried_coal":data1.te_fluorine_f_typical_in_air_dried_coal,
"te_fluorine_f_minimum_in_air_dried_coal":data1.te_fluorine_f_minimum_in_air_dried_coal,
"te_fluorine_f_maximum_in_air_dried_coal":data1.te_fluorine_f_maximum_in_air_dried_coal,
"te_mercury_hg_typical_in_air_dried_coal":data1.te_mercury_hg_typical_in_air_dried_coal,
"te_mercury_hg_minimum_in_air_dried_coal":data1.te_mercury_hg_minimum_in_air_dried_coal,
"te_mercury_hg_maximum_in_air_dried_coal":data1.te_mercury_hg_maximum_in_air_dried_coal,
"te_molybdenum_mo_typical_in_air_dried_coal":data1.te_molybdenum_mo_typical_in_air_dried_coal,
"te_molybdenum_mo_minimum_in_air_dried_coal":data1.te_molybdenum_mo_minimum_in_air_dried_coal,
"te_molybdenum_mo_maximum_in_air_dried_coal":data1.te_molybdenum_mo_maximum_in_air_dried_coal,
"te_lead_pb_typical_in_air_dried_coal":data1.te_lead_pb_typical_in_air_dried_coal,
"te_lead_pb_minimum_in_air_dried_coal":data1.te_lead_pb_minimum_in_air_dried_coal,
"te_lead_pb_maximum_in_air_dried_coal":data1.te_lead_pb_maximum_in_air_dried_coal,
"te_phosphorus_p_typical_in_air_dried_coal":data1.te_phosphorus_p_typical_in_air_dried_coal,
"te_phosphorus_p_minimum_in_air_dried_coal":data1.te_phosphorus_p_minimum_in_air_dried_coal,
"te_phosphorus_p_maximum_in_air_dried_coal":data1.te_phosphorus_p_maximum_in_air_dried_coal,
"te_selenium_se_typical_in_air_dried_coal":data1.te_selenium_se_typical_in_air_dried_coal,
"te_selenium_se_minimum_in_air_dried_coal":data1.te_selenium_se_minimum_in_air_dried_coal,
"te_selenium_se_maximum_in_air_dried_coal":data1.te_selenium_se_maximum_in_air_dried_coal,
"te_vanadium_v_typical_in_air_dried_coal":data1.te_vanadium_v_typical_in_air_dried_coal,
"te_vanadium_v_minimum_in_air_dried_coal":data1.te_vanadium_v_minimum_in_air_dried_coal,
"te_vanadium_v_maximum_in_air_dried_coal":data1.te_vanadium_v_maximum_in_air_dried_coal,
"te_zinc_zn_typical_in_air_dried_coal":data1.te_zinc_zn_typical_in_air_dried_coal,
"te_zinc_zn_minimum_in_air_dried_coal":data1.te_zinc_zn_minimum_in_air_dried_coal,
"te_zinc_zn_maximum_in_air_dried_coal":data1.te_zinc_zn_maximum_in_air_dried_coal,
"te_arsenic_as_typical":data1.te_arsenic_as_typical,
"te_arsenic_as_minimum":data1.te_arsenic_as_minimum,
"te_arsenic_as_maximum":data1.te_arsenic_as_maximum,
"te_antimony_sb_typical":data1.te_antimony_sb_typical,
"te_antimony_sb_minimum":data1.te_antimony_sb_minimum,
"te_antimony_sb_maximum":data1.te_antimony_sb_maximum,
"te_boron_b_typical":data1.te_boron_b_typical,
"te_boron_b_minimum":data1.te_boron_b_minimum,
"te_boron_b_maximum":data1.te_boron_b_maximum,
"te_cadmium_cd_typical":data1.te_cadmium_cd_typical,
"te_cadmium_cd_minimum":data1.te_cadmium_cd_minimum,
"te_cadmium_cd_maximum":data1.te_cadmium_cd_maximum,
"te_chromium_cr_typical":data1.te_chromium_cr_typical,
"te_chromium_cr_minimum":data1.te_chromium_cr_minimum,
"te_chromium_cr_maximum":data1.te_chromium_cr_maximum,
"te_copper_cu_typical":data1.te_copper_cu_typical,
"te_copper_cu_minimum":data1.te_copper_cu_minimum,
"te_copper_cu_maximum":data1.te_copper_cu_maximum,
"te_chlorine_cl_typical":data1.te_chlorine_cl_typical,
"te_chlorine_cl_minimum":data1.te_chlorine_cl_minimum,
"te_chlorine_cl_maximum":data1.te_chlorine_cl_maximum,
"te_fluorine_f_typical":data1.te_fluorine_f_typical,
"te_fluorine_f_minimum":data1.te_fluorine_f_minimum,
"te_fluorine_f_maximum":data1.te_fluorine_f_maximum,
"te_mercury_hg_typical":data1.te_mercury_hg_typical,
"te_mercury_hg_minimum":data1.te_mercury_hg_minimum,
"te_mercury_hg_maximum":data1.te_mercury_hg_maximum,
"te_molybdenum_mo_typical":data1.te_molybdenum_mo_typical,
"te_molybdenum_mo_minimum":data1.te_molybdenum_mo_minimum,
"te_molybdenum_mo_maximum":data1.te_molybdenum_mo_maximum,
"te_lead_pb_typical":data1.te_lead_pb_typical,
"te_lead_pb_minimum":data1.te_lead_pb_minimum,
"te_lead_pb_maximum":data1.te_lead_pb_maximum,
"te_phosphorus_p_typical":data1.te_phosphorus_p_typical,
"te_phosphorus_p_minimum":data1.te_phosphorus_p_minimum,
"te_phosphorus_p_maximum":data1.te_phosphorus_p_maximum,
"te_selenium_se_typical":data1.te_selenium_se_typical,
"te_selenium_se_minimum":data1.te_selenium_se_minimum,
"te_selenium_se_maximum":data1.te_selenium_se_maximum,
"te_vanadium_v_typical":data1.te_vanadium_v_typical,
"te_vanadium_v_min":data1.te_vanadium_v_min,
"te_vanadium_v_max":data1.te_vanadium_v_max,
"te_zinc_zn_typical":data1.te_zinc_zn_typical,
"te_zinc_zn_min":data1.te_zinc_zn_min,
"te_zinc_zn_max":data1.te_zinc_zn_max,
"size_0_to_50mm_typical":data1.size_0_to_50mm_typical,
"size_0_to_50mm_min":data1.size_0_to_50mm_min,
"size_0_to_50mm_max":data1.size_0_to_50mm_max,
"above_0_to_50mm_typical":data1.above_0_to_50mm_typical,
"above_0_to_50mm_min":data1.above_0_to_50mm_min,
"above_0_to_50mm_max":data1.above_0_to_50mm_max,
"under_2mm_typical":data1.under_2mm_typical,
"under_2mm_min":data1.under_2mm_typical,
"under_2mm_max":data1.under_2mm_max,
"size_above_2mm_typical":data1.size_above_2mm_typical,
"size_above_2mm_min":data1.size_above_2mm_min,
"size_above_2mm_max":data1.size_above_2mm_max,
"product_status":data1.product_status,
"product_year":data1.product_year,
"shipping_term":data1.shipping_term,
"vessel_type":data1.vessel_type,
"purchase_contract":data1.purchase_contract,
"buyer_contract":data1.buyer_contract,
"contract_shipment":data1.contract_shipment,
// "laycan_from_date":format(inquiryDate, 'dd/MM/yyyy'),
// "laycan_to_date":format(closeDate, 'dd/MM/yyyy'),
"testing_method":data1.testing_method,

    };
    console.log(JSON.stringify(responseData))

    axios.put(`${API_URL}update/product_new/${id}`, responseData, {
      headers: {
        Authorization: `Bearer ${decryptedAccessToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response)
        if(response.data.success === true) {
          const messageContainer = document.getElementById('messageContainer');

          // Create the success message element
          const successMessage = document.createElement('div');
          successMessage.className = 'success-message';
          successMessage.innerText = response.data.msg;

          // Append the success message to the container
          messageContainer.appendChild(successMessage);

          // Scroll to the top
          window.scrollTo({
              top: 0,
              behavior: "smooth"
          });

          // Hide the message after 2 seconds
          setTimeout(() => {
              successMessage.remove();
          }, 2000);

          setData(response);
          setIsEditMode(false);
          fetchData();
      }
        if(response.data.success===false){
          const messageContainer = document.getElementById('messageContainer');

          // Create the success message element
          const successMessage = document.createElement('div');
          successMessage.className = 'error-message';
          successMessage.innerText = response.data.msg;

          // Append the success message to the container
          messageContainer.appendChild(successMessage);

          // Scroll to the top
          window.scrollTo({
              top: 0,
              behavior: "smooth"
          });
          setTimeout(() => {
            successMessage.remove();
        }, 2000);
        }

      })
      .catch((error) => console.error(error));
  };

// edit page

// /get/file/<id>
  return (
    <div id="view-page" className="fulid-section">
      <Header />
      <div className="row">
      <div className="col-md-2">
            <RecentItems/>
          </div>
          <div className="col-md-10 right-section">
      <div className="contactAccount ">
        <div className="page-header" id="add-account">
          <div>




            <h2 className="main-content-title tx-24 mg-b-5">Product Detalis</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard / Product Detalis
              </li>
            </ol>
          </div>
        </div>
      </div>

        <>
       
      {isEditMode ? (
       <div className="tasks">
        <div id="messageContainer"></div>
       <p className="edit-btn">
       <input
      type="submit"
      className="account-save"
      onClick={handleSaveEdit}
      value="Save"
    />
         <button className="btn btn-primary" onClick={handleCancelEdit}>
           Cancel
         </button>
       </p>
           <Row className="mb-3 product-heading">
             <h4 className='heading'>Information</h4>
             {/* <h4 className='heading'>Account Information</h4> */}


             <div className="form-grop col-md-6">
            <label htmlFor="product-code">Product Master</label>
            <select
              className="form-control"
              id="product_master"
              name="product_master"
              defaultValue={data1.product_code||productCode || contracts.contract  || formData.product_master}
              onChange={dropdownChange}
            >
              <option defaultValue="">Select a Product Master</option>
              {products.map((product) => (
                <option key={product.id} defaultValue={product.product_code}>
                  {product.product_code}
                </option>
              ))}
            </select>
            </div>
            <Form.Group as={Col} md="6">
               <FloatingLabel
                 controlId="floatingInput"
                 label="Product Family"
                 className="mb-3"
               >

                 <Form.Control type="text"
                 placeholder="Product Family"
                 name="product_family"
                 defaultValue={data1.product_family }
         onChange={handleChange}
                 />
               </FloatingLabel>
             </Form.Group><Form.Group as={Col} md="6">
               <FloatingLabel
                 controlId="floatingInput"
                 label="Product Code"
                 className="mb-3"
               >

                 <Form.Control type="text"
                 placeholder="Product Code"
                 name="product_code"
                 disabled
                 defaultValue={data1.product_code || productApend }
         onChange={handleChange}
                 />
               </FloatingLabel>
             </Form.Group>
             <Form.Group as={Col} md="6">

                  <input
                    type='checkbox'
                    name='locked'
                    checked={lockedOne} // Use the state variable to determine if the checkbox is checked
                    onChange={handleCheckboxLocked} // Handle the change event
                    className='mx-3'
                  /><label>Locked</label>
                </Form.Group>

              <Form.Group as={Col} md="6">

                   <FloatingLabel controlId="floatingSelect"
                   className='dropDown' label="Supplier">
         <Form.Select aria-label="Floating label select example"
         name="supplier"
         placeholder="Supplier"
         value={data1.supplier}
         // defaultValue={}
         onChange={handleChange}
         >

     <option defaultValue='' >Select </option>
              {
                 productAccount.map((x)=>{
                     return(
                         <option value={x.account_name}>{x.account_name}</option>
                     )
                 })
              }


         </Form.Select>

       </FloatingLabel>

             </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingTextarea2"
                     label="Product Description"
                   >
                     <Form.Control
                       as="textarea"
                       placeholder="Product Description"
                       style={{ height: "200px!important" }}
                       name="product_description"
                       defaultValue={data1.product_description}
                       onChange={handleChange}
                     />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Product Name"
                     className="mb-3"
                   >
                     <Form.Control
                       type="text"
                       placeholder="Product Name"
                       name="product_name"
                       className="error-validation"
                       defaultValue={data1.product_name}
                       onChange={handleChange}
                     />
                   </FloatingLabel>
                 </Form.Group>

              <Form.Group as={Col} md="6">

                   <FloatingLabel controlId="floatingSelect"
                   className='dropDown' label="Purchase Contract">
         <Form.Select aria-label="Floating label select example"
         name="purchase_contract"
         placeholder="Purchase Contract"
         value={data1.purchase_contract}
         onChange={handleChange}
         >

     <option value='' >Select </option>
              {
                 puchaseContract.map((x)=>{
                     return(
                         <option value={x.contract_number}>{x.contract_number}</option>
                     )
                 })
              }


         </Form.Select>

       </FloatingLabel>
     </Form.Group>
                 <Form.Group as={Col} md="6">
               <FloatingLabel
                 controlId="floatingInput"
                 label="Buyer Name"
                 className="mb-3"


               >
                 <Form.Control
                   type="text"
                   placeholder="Buyer Name"
                   name="buyer_name"
                   defaultValue={data1.buyer_name}
         onChange={handleChange}  />
               </FloatingLabel>
             </Form.Group>
             <Form.Group as={Col} md="6">

                   <FloatingLabel controlId="floatingSelect"
                   className='dropDown' label="Buyer Contract">
         <Form.Select aria-label="Floating label select example"
         name="buyer_contract"
         placeholder="Buyer Contract"
         value={data1.buyer_contract || ''}
         onChange={handleChange}
         >

     <option value='' >Select </option>
              {
                 datasContract.map((x)=>{
                     return(
                         <option value={x.contract_number}>{x.contract_number}</option>
                     )
                 })
              }


         </Form.Select>

       </FloatingLabel>
     </Form.Group>



     <Form.Group as={Col} md="6">

                   <FloatingLabel controlId="floatingSelect"
                   className='dropDown' label="Contract Shipment">
         <Form.Select aria-label="Floating label select example"
         name="contract_shipment"
         placeholder="Contract Shipment"
         value={data1.contract_shipment}
         onChange={handleChange}
         >

     <option defaultValue='' >Select </option>
              {
                 contractShippment.map((x)=>{
                     return(
                         <option value={x.contract_ship_code}>{x.contract_ship_code }</option>
                     )
                 })
              }


         </Form.Select>

       </FloatingLabel>

             </Form.Group>


           </Row>
           <Row className="mb-3">
             <h4 className='heading'>Product Availability</h4>
             <Form.Group as={Col} md="6">
               <FloatingLabel
                 controlId="floatingInput"
                 label="quantity MT"
                 className="mb-3"
               >

                 <Form.Control type="text"
                 placeholder="quantity MT"
                 name="quantity_MT"
                 defaultValue={data1.quantity_MT}
         onChange={handleChange}  />
               </FloatingLabel>
             </Form.Group>
             <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingSelect"
                       className="dropDown"
                       label="product_status"
                     >
                       <Form.Select
                         aria-label="Floating label select example"
                         name="product_status"
                         placeholder="product status"
                         defaultValue={data1.product_status}
                         onChange={handleChange}

                       >
                        <option defaultValue="">--None--</option><option defaultValue="UnSold">UnSold</option><option defaultValue="Offered">Offered</option><option defaultValue="Approved">Approved</option><option defaultValue="Sold" selected="selected">Sold</option><option defaultValue="Rejected">Rejected</option><option defaultValue="Allocated">Allocated</option><option defaultValue="Cancelled">Cancelled</option><option defaultValue="Shipments - Arutmin">Shipments - Arutmin</option>
                       </Form.Select>
                     </FloatingLabel>
                   </Form.Group>


                   <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingSelect"
                       className="dropDown"
                       label="product year"
                     >
                       <Form.Select
                         aria-label="Floating label select example"
                         name="product_year"
                         placeholder="product year"
                         value={data1.product_year}
                         onChange={handleChange}

                       >
                       <option value="">--None--</option><option value="2016">2016</option><option value="2017" selected="selected">2017</option><option value="2018">2018</option><option value="2019">2019</option><option value="2020">2020</option><option value="2021">2021</option><option value="2022">2022</option><option value="2014">2014</option><option value="2015">2015</option><option value="2023">2023</option><option value="2024">2024</option>
                       </Form.Select>
                     </FloatingLabel>
                   </Form.Group>
                <Form.Group as={Col} md="6">
                   <FloatingLabel
                 controlId="floatingInput"
                 label="Laycan From Date"
                 className="mb-3 datepick"


               >
                  <DatePicker
        selected={inquiryDate}
        selectsStart
        onChange={handleInquiryChange}
        dateFormat="dd/MM/yyyy"
      />


               </FloatingLabel>
             </Form.Group>

             <Form.Group as={Col} md="6">
                     <FloatingLabel
                       controlId="floatingSelect"
                       className="dropDown"
                       label="product month"
                     >
                       <Form.Select
                         aria-label="Floating label select example"
                         name="product_month"
                         placeholder="product month"
                         defaultValue={data1.product_month}
                         onChange={handleChange}

                       >
                       <option defaultValue="">--None--</option><option defaultValue="January" selected="selected">January</option><option defaultValue="February">February</option><option defaultValue="March">March</option><option defaultValue="April">April</option><option defaultValue="May">May</option><option defaultValue="June">June</option><option defaultValue="July">July</option><option defaultValue="August">August</option><option defaultValue="September">September</option><option defaultValue="October">October</option><option defaultValue="November">November</option><option defaultValue="December">December</option>
                       </Form.Select>
                     </FloatingLabel>
                   </Form.Group>

                   <Form.Group as={Col} md="6">
                   <FloatingLabel
                 controlId="floatingInput"
                 label="Laycan To Date"
                 className="mb-3 datepick"


               >
                   <DatePicker
               selectsStart
        selected={closeDate}
        onChange={handleCloseChange}
        dateFormat="dd/MM/yyyy"
      />

               </FloatingLabel>
             </Form.Group>


             <Form.Group as={Col} md="6">

                   <FloatingLabel controlId="floatingSelect"
                   className='dropDown' label="Product price type">
         <Form.Select aria-label="Floating label select example"
         name="price_type"
         placeholder="Product Price type"
         value={data1.price_type}
         onChange={handleChange}
         >
           <option value='' >Select</option>
            {
               getPrice.map((x)=>{
                   return(
                       <option value={x.price_type_name}>{x.price_type_name}</option>
                   )
               })
            }
         </Form.Select>

       </FloatingLabel>
                   </Form.Group>

                    <Form.Group as={Col} md="6">
               <FloatingLabel
                 controlId="floatingInput"
                 label="Port Of Loading"
                 className="mb-3"
               >

                 <Form.Control type="text"
                 placeholder="Port Of Loading"
                 name="port_of_loading"
                 defaultValue={data1.port_of_loading}
         onChange={handleChange}
         />
               </FloatingLabel>
             </Form.Group>

             <Form.Group as={Col} md="6">
               <FloatingLabel
                 controlId="floatingInput"
                 label="Price"
                 className="mb-3"
               >

                 <Form.Control type="text"
                 placeholder="Price"
                 name="price"
                 defaultValue={data1.price}
         onChange={handleChange}  />
               </FloatingLabel>
             </Form.Group>


             <Form.Group as={Col} md="6">

                   <FloatingLabel controlId="floatingSelect"
                   className='dropDown' label="Shipping Term">
         <Form.Select aria-label="Floating label select example"
         name="shipping_term"
         placeholder="Shipping Term"
         defaultValue={data1.shipping_term}
         onChange={handleChange}
         >
           <option defaultValue="">--None--</option><option defaultValue="FOB-B">FOB-B</option><option defaultValue="FOB-MV" selected="selected">FOB-MV</option><option defaultValue="CFR-MV">CFR-MV</option><option defaultValue="CIF-MV">CIF-MV</option><option defaultValue="FAS">FAS</option>
         </Form.Select>

       </FloatingLabel>
                   </Form.Group>

             <Form.Group as={Col} md="6">
               <FloatingLabel
                 controlId="floatingInput"
                 label="Product Price Basis"
                 className="mb-3"
               >

                 <Form.Control type="text"
                 placeholder="Product Price Basis"
                 name="product_price_basis"
                 defaultValue={data1.product_price_basis}
         onChange={handleChange}  />
               </FloatingLabel>
             </Form.Group>
             <Form.Group as={Col} md="6">

                   <FloatingLabel controlId="floatingSelect"
                   className='dropDown' label="Vessel Type ">
         <Form.Select aria-label="Floating label select example"
         name="vessel_type"
         placeholder="Vessel Type  "
         value={data1.vessel_type}
         onChange={handleChange}
         >
          <option value="">--None--</option>
          <option value="Geared (GnG)" >Geared (GnG)</option>
<option value="Gearless">Gearless</option>
<option value="Geared">Geared</option>
<option value="Barge">Barge</option>
         </Form.Select>

       </FloatingLabel>
                   </Form.Group>


             </Row>
           <Row className="mb-3">
             <h4 className='heading'>%As Received Basis</h4>

                     {/* <h4 className='heading'>Account Information</h4> */}
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Total Moisture Typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Total Moisture Typical"
                           name="total_moisture_typical"
                           defaultValue={data1.moisture_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="sulphur typical arb"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="sulphur typical arb"
                           name="sulphur_typical_arb"
                           defaultValue={data1.sulphur_typical_arb}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="total moisture minimum"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="total moisture minimum"
                           name="total_moisture_minimum"
                           defaultValue={data1.total_moisture_minimum}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="sulphur minimum arb"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="sulphur minimum arb"
                           name="sulphur_minimum_arb"
                           defaultValue={data1.sulphur_minimum_arb}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="total moisture maximum"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="total moisture maximum"
                           name="total_moisture_maximum"
                           defaultValue={data1.total_moisture_maximum}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="sulphur maximum arb "
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="sulphur maximum arb "
                           name="sulphur_maximum_arb"
                           defaultValue={data1.sulphur_maximum_arb}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="ash typical arb"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="ash typical arb"
                           name="ash_typical_arb"
                           defaultValue={data1.ash_typical_arb}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>

                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="volatile matter typical arb"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="volatile matter typical arb"
                           name="volatile_matter_typical_arb"
                           defaultValue={data1.volatile_matter_typical_arb}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="ash minimum arb "
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="ash minimum arb "
                           name="ash_minimum_arb "
                           defaultValue={data1.ash_minimum_arb }
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="volatile matter minimum arb"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="volatile matter minimum arb"
                           name="volatile_matter_minimum_arb"
                           defaultValue={data1.volatile_matter_minimum_arb}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>

                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="ash maximum arb "
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="ash maximum arb "
                           name="ash_maximum_arb "
                           defaultValue={data1.ash_maximum_arb }
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                       <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="volatile matter maximum arb"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="volatile matter maximum arb"
                           name="volatile_matter_maximum_arb"
                           defaultValue={data1.volatile_matter_maximum_arb}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
             </Row>

             <Row className="mb-3">
                     <h4 className='heading'>Proximate Analysis %Air Dried Basis</h4>

                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="moisture typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="moisture typical"
                           name="moisture_typical"
                           defaultValue={data1.moisture_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="volatile matter typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="volatile matter typical"
                           name="volatile_matter_typical"
                           defaultValue={data1.volatile_matter_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="moisture minimum"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="moisture minimum"
                           name="moisture_minimum"
                           defaultValue={data1.moisture_minimum}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="volatile matter minimum"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="volatile matter minimum"
                           name="volatile_matter_minimum"
                           defaultValue={data1.volatile_matter_minimum}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="moisture maximum"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="moisture maximum"
                           name="moisture_maximum"
                           defaultValue={data1.moisture_maximum}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="volatile matter maximum"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="volatile matter maximum"
                           name="volatile_matter_maximum"
                           defaultValue={data1.volatile_matter_maximum}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="ash typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="ash typical"
                           name="ash_typical"
                           defaultValue={data1.ash_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Fixed Carbon By Difference "
                         className="mb-3"


                       >
                         <Form.Control
                           type="checkbox"
                           placeholder="Fixed Carbon By Difference "
                           name="fixed_carbon_by_difference"
                           defaultValue={data1.fixed_carbon_by_difference}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="ash minimum"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="ash minimum"
                           name="ash_minimum"
                           defaultValue={data1.ash_minimum}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="fixed carbon typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="fixed carbon typical"
                           name="fixed_carbon_typical"
                           defaultValue={data1.fixed_carbon_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="ash maximum"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="ash maximum"
                           name="ash_maximum"
                           defaultValue={data1.ash_maximum}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="fixed carbon minimum"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="fixed carbon minimum"
                           name="fixed_carbon_minimum"
                           defaultValue={data1.fixed_carbon_minimum}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="fixed carbon maximum"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="fixed carbon maximum"
                           name="fixed_carbon_maximum"
                           defaultValue={data1.fixed_carbon_maximum}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                   </Row>
                   <Row className="mb-3">
                     <h4 className='heading'>CALORIFIC VALUE, Keal/kg</h4>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Gross Air Dried Typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Gross Air Dried Typical"
                           name="gross_air_dried_typical"
                           defaultValue={data1.gross_air_dried_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>


                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="net as received typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="net as received typical"
                           name="net_as_received_typical"
                           defaultValue={data1.net_as_received_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="gross air dried min"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="gross air dried min"
                           name="gross_air_dried_min"
                           defaultValue={data1.gross_air_dried_min}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="net as received min"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="net as received min"
                           name="net_as_received_min"
                           defaultValue={data1.net_as_received_min}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="gross air dried max"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="gross air dried max"
                           name="gross_air_dried_max"
                           defaultValue={data1.gross_air_dried_max}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="net as received max"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="net as received max"
                           name="net_as_received_max"
                           defaultValue={data1.net_as_received_max}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="gross as received typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="gross as received typical"
                           name="gross_as_received_typical"
                           defaultValue={data1.gross_as_received_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="gross as received min"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="gross as received min"
                           name="gross_as_received_min"
                           defaultValue={data1.gross_as_received_min}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="ggross as received max"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="gross as received max"
                           name="gross_as_received_max"
                           defaultValue={data1.gross_as_received_max}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group></Row>
                   <Row className="mb-3">
                     <h4 className='heading'>HGI</h4>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="HGI typical"
                         className="mb-3"
                       >

                         <Form.Control type="text"
                         placeholder="HGI typical"
                         name="hgi_typical"
                         defaultValue={data1.hgi_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6"></Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="HGI minimum"
                         className="mb-3"
                       >

                         <Form.Control type="text"
                         placeholder="HGI minimum"
                         name="hgi_min"
                         defaultValue={data1.hgi_min}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6"></Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="HGI Maximum"
                         className="mb-3"
                       >

                         <Form.Control type="text"
                         placeholder="HGI Maximum"
                         name="hgi_max"
                         defaultValue={data1.hgi_max}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     </Row>
                     <Row className="mb-3">
                     <h4 className='heading'>ULTIMATE ANALYSIS %dry ash free basis</h4>

                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Carbon typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="Carbon typical"
                           name="carbon_typical"
                           defaultValue={data1.carbon_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="sulphur typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="sulphur typical"
                           name="sulphur_typical"
                           defaultValue={data1.sulphur_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="carbon min"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="carbon min"
                           name="carbon_min"
                           defaultValue={data1.carbon_min}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="sulphur min"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="sulphur min"
                           name="sulphur_min"
                           defaultValue={data1.sulphur_min}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="carbon max"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="carbon max"
                           name="carbon_max"
                           defaultValue={data1.carbon_max}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="sulphur min"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="sulphur min"
                           name="sulphur_min"
                           defaultValue={data1.sulphur_min}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="hydrogen typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="hydrogen typical"
                           name="hydrogen_typical"
                           defaultValue={data1.hydrogen_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="oxygen typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="oxygen typical"
                           name="oxygen_typical"
                           defaultValue={data1.oxygen_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="hydrogen min"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="hydrogen min"
                           name="hydrogen_min"
                           defaultValue={data1.hydrogen_min}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="oxygen min"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="oxygen min"
                           name="oxygen_min"
                           defaultValue={data1.oxygen_min}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="hydrogen max"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="hydrogen max"
                           name="hydrogen_max"
                           defaultValue={data1.hydrogen_max}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="oxygen max"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="oxygen max"
                           name="oxygen_max"
                           defaultValue={data1.oxygen_max}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="nitrogen typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="nitrogen typical"
                           name="nitrogen_typical"
                           defaultValue={data1.nitrogen_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="nitrogen min"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="nitrogen min"
                           name="nitrogen_min"
                           defaultValue={data1.nitrogen_min}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="nitrogen max"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="nitrogen max"
                           name="nitrogen_max"
                           defaultValue={data1.nitrogen_max}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                   </Row>
                   <Row className="mb-3">
                     <h4 className='heading'>SULPHUR %air dried basis</h4>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Sulphur Typical Adb"
                         className="mb-3"


                       >
                         <Form.Control
                           type="number"
                           placeholder="Sulphur Typical Adb"
                           name="sulphur_typical_adb"
                           defaultValue={data1.sulphur_typical_adb}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>


                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Sulphur Minimum Adb"
                         className="mb-3"
                       >

                         <Form.Control type="number"
                         placeholder="Sulphur Minimum Adb"
                         name="sulphur_min_adb"
                         defaultValue={data1.sulphur_min_adb}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>



                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Sulphur Maximum"
                         className="mb-3"
                       >

                         <Form.Control type="number"
                         placeholder="Sulphur Maximum"
                         name="product_sulphur_max"
                         defaultValue={data1.product_sulphur_max}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>

                   </Row>
                   <Row className="mb-3">
                     <h4 className='heading'>ASH FUSION TEMPERATURE, DEG (C) Reducing</h4>

                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="initial typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="initial typical"
                           name="initial_typical"
                           defaultValue={data1.initial_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="hemispherical typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="hemispherical typical"
                           name="hemispherical_typical"
                           defaultValue={data1.hemispherical_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="initial minimum"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="initial minimum"
                           name="initial_minimum"
                           defaultValue={data1.initial_minimum}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="hemispherical minimum"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="hemispherical minimum"
                           name="hemispherical_minimum"
                           defaultValue={data1.hemispherical_minimum}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="initial maximum"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="initial maximum"
                           name="initial_maximum"
                           defaultValue={data1.initial_maximum}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="hemispherical maximum"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="hemispherical maximum"
                           name="hemispherical_maximum"
                           defaultValue={data1.hemispherical_maximum}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="spherical typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="spherical typical"
                           name="spherical_typical"
                           defaultValue={data1.spherical_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="flow typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="flow typical"
                           name="flow_typical"
                           defaultValue={data1.flow_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="spherical minimum"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="spherical minimum"
                           name="spherical_minimum"
                           defaultValue={data1.spherical_minimum}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="flow minimum"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="flow minimum"
                           name="flow_minimum"
                           defaultValue={data1.flow_minimum}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="spherical maximum"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="spherical maximum"
                           name="spherical_maximum"
                           defaultValue={data1.spherical_maximum}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="flow maximum"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="flow maximum"
                           name="flow_maximum"
                           defaultValue={data1.flow_maximum}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>




                      </Row>
                      <Row className="mb-3">
                     <h4 className='heading'>ASH FUSION TEMPERATURE, Deg C Oxidizing</h4>
                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="Oxidizing Initial Deformtion Typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="number"
                           placeholder="Oxidizing Initial Deformtion Typical"
                           name="oxidizing_initial_typical"
                           defaultValue={data1.oxidizing_initial_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>

                     <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="oxidising hemispherical typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="oxidising hemispherical typical"
                           name="oxidising_hemispherical_typical"
                           defaultValue={data1.oxidising_hemispherical_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="oxidising initial minimum"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="oxidising initial minimum"
                           name="oxidising_initial_minimum"
                           defaultValue={data1.oxidising_initial_minimum}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="oxidising hemispherical minimum"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="oxidising hemispherical minimum"
                           name="oxidising_hemispherical_minimum"
                           defaultValue={data1.oxidising_hemispherical_minimum}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="oxidising initial maximum"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="oxidising initial maximum"
                           name="oxidising_initial_maximum"
                           defaultValue={data1.oxidising_initial_maximum}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="oxidising hemispherical maximum"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="oxidising hemispherical maximum"
                           name="oxidising_hemispherical_maximum"
                           defaultValue={data1.oxidising_hemispherical_maximum}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="oxidising spherical typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="oxidising spherical typical"
                           name="oxidising_spherical_typical"
                           defaultValue={data1.oxidising_spherical_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="oxidising flow typical"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="oxidising flow typical"
                           name="oxidising_flow_typical"
                           defaultValue={data1.oxidising_flow_typical}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="oxidising spherical minimum"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="oxidising spherical minimum"
                           name="oxidising_spherical_minimum"
                           defaultValue={data1.oxidising_spherical_minimum}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="oxidising flow minimum"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="oxidising flow minimum"
                           name="oxidising_flow_minimum"
                           defaultValue={data1.oxidising_flow_minimum}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="oxidising spherical maximum"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="oxidising spherical maximum"
                           name="oxidising_spherical_maximum"
                           defaultValue={data1.oxidising_spherical_maximum}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                      <Form.Group as={Col} md="6">
                       <FloatingLabel
                         controlId="floatingInput"
                         label="oxidising flow maximum"
                         className="mb-3"


                       >
                         <Form.Control
                           type="text"
                           placeholder="oxidising flow maximum"
                           name="oxidising_flow_maximum"
                           defaultValue={data1.oxidising_flow_maximum}
         onChange={handleChange}  />
                       </FloatingLabel>
                     </Form.Group>
                     </Row>

               <Row className="mb-3">
                 <h4 className="heading">ASH ANALYSIS %Dry Basis in Ash</h4>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="SiO2 Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.SiO2_Typical}
         onChange={handleChange}
                       type="number"
                       placeholder="SiO2 Typical"
                       name="SiO2_Typical"
                      />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TiO2 Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.TiO2_Typical}
         onChange={handleChange}
                       type="number"
                       placeholder="TiO2 Typical"
                       name="TiO2_Typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="SiO2 Minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.SiO2_Minimum}
         onChange={handleChange}
                       type="number"
                       placeholder="SiO2 Minimum"
                       name="SiO2_Minimum"
                      />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TiO2 Minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.TiO2_Minimum}
         onChange={handleChange}
                       type="number"
                       placeholder="TiO2 Minimum"
                       name="TiO2_Minimum"

                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="SiO2 Maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.SiO2_Maximum}
         onChange={handleChange}
                       type="number"
                       placeholder="SiO2 Maximum"
                       name="SiO2_Maximum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TiO2 Maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.TiO2_Maximum}
         onChange={handleChange}
                       type="number"
                       placeholder="TiO2 Maximum"
                       name="TiO2_Maximum"/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="AI2O3 Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.AI2O3_Typical}
         onChange={handleChange}
                       type="number"
                       placeholder="AI2O3 Typical"
                       name="AI2O3_Typical"/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Na2O Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.Na2O_Typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Na2O Typical"
                       name="Na2O_Typical" />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Al2O3 Minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.Al2O3_Minimum}
         onChange={handleChange}
                       type="number"
                       placeholder="Al2O3 Minimum"
                       name="Al2O3_Minimum" />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Na2O Minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.Na2O_Minimum}
         onChange={handleChange}
                       type="number"
                       placeholder="Na2O Minimum"
                       name="Na2O_Minimum" />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="AI2O3 Maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.Al2O3_Maximum}
         onChange={handleChange}
                       type="number"
                       placeholder="AI2O3 Maximum"
                       name="Al2O3_Maximum" />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Na2O Maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.Na2O_Maximum}
         onChange={handleChange}
                       type="number"
                       placeholder="Na2O Maximum"
                       name="Na2O_Maximum" />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Fe2O3 Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.Fe2O3_Typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Fe2O3 Typical"
                       name="Fe2O3_Typical" />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="K2O Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.K2O_Typical}
         onChange={handleChange}
                       type="number"
                       placeholder="K2O Typical"
                       name="K2O_Typical" />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Fe2O3 Minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.Fe2O3_Minimum}
         onChange={handleChange}
                       type="number"
                       placeholder="Fe2O3 Minimum"
                       name="Fe2O3_Minimum" />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="K2O Minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.K2O_Minimum}
         onChange={handleChange}
                       type="number"
                       placeholder="K2O Minimum"
                       name="K2O_Minimum"/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Fe2O3 Maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.Fe2O3_Maximum}
         onChange={handleChange}
                       type="number"
                       placeholder="Fe2O3 Maximum"
                       name="Fe2O3_Maximum"/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="K2O Maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.K2O_Maximum}
         onChange={handleChange}
                       type="number"
                       placeholder="K2O Maximum"
                       name="K2O_Maximum"/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="P2O5 Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.P2O5_Typical}
         onChange={handleChange}
                       type="number"
                       placeholder="P2O5 Typical"
                       name="P2O5_Typical"/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="CaO Minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.CaO_Minimum}
         onChange={handleChange}
                       type="number"
                       placeholder="CaO Minimum"
                       name="CaO_Minimum"/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="P2O5 Minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.P2O5_Minimum}
         onChange={handleChange}
                       type="number"
                       placeholder="P2O5 Minimum"
                       name="P2O5_Minimum"/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="CaO Maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.CaO_Maximum}
         onChange={handleChange}
                       type="number"
                       placeholder="CaO Maximum"
                       name="CaO_Maximum"/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="P2O5 Maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.P2O5_Maximum}
         onChange={handleChange}
                       type="number"
                       placeholder="P2O5 Maximum"
                       name="P2O5_Maximum"/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="MgO Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.MgO_Typical}
         onChange={handleChange}
                       type="number"
                       placeholder="MgO Typical"
                       name="MgO_Typical"/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="SO3 Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.SO3_Typical}
         onChange={handleChange}
                       type="number"
                       placeholder="SO3 Typical"
                       name="SO3_Typical"/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="MgO Minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.MgO_Minimum}
         onChange={handleChange}
                       type="number"
                       placeholder="MgO Minimum"
                       name="MgO_Minimum"/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="SO3 Minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.SO3_Minimum}
         onChange={handleChange}
                       type="number"
                       placeholder="SO3 Minimum"
                       name="SO3_Minimum"/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="MgO Maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.MgO_Maximum}
         onChange={handleChange}
                       type="number"
                       placeholder="MgO Maximum"
                       name="MgO_Maximum"/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="SO3 Maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.SO3_Maximum}
         onChange={handleChange}
                       type="number"
                       placeholder="SO3 Maximum"
                       name="SO3_Maximum"/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Mn3O4 Typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.Mn3O4_Typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Mn3O4 Typical"
                       name="Mn3O4_Typical"/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6"></Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Mn3O4 Minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.Mn3O4_Minimum}
         onChange={handleChange}
                       type="number"
                       placeholder="Mn3O4 Minimum"
                       name="Mn3O4_Minimum"/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6"></Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Mn3O4 Maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.Mn3O4_Maximum}
         onChange={handleChange}
                       type="number"
                       placeholder="Mn3O4 Maximum"
                       name="Mn3O4_Maximum"/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6"></Form.Group>
               </Row>
               <Row className="mb-3">
                 <h4 className="heading">Trace Elements, ppm Air Dried in Coal</h4>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE arsenic as typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_arsenic_as_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE arsenic as typical"
                       name="te_arsenic_as_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE rsenic as minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_arsenic_as_minimum_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE rsenic as minimum"
                       name="te_arsenic_as_minimum_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE arsenic as maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_arsenic_as_maximum_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE arsenic as maximum"
                       name="te_arsenic_as_maximum_in_air_dried_coal"
                        />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE antimony sb typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_antimony_sb_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE antimony sb typical"
                       name="te_antimony_sb_typical_in_air_dried_coal"
                        />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE antimony sb minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_antimony_sb_minimum_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE antimony sb minimum"
                       name="te_antimony_sb_minimum_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE antimony sb maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_antimony_sb_maximum_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE antimony sb maximum"
                       name="te_antimony_sb_maximum_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE boron b typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_boron_b_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE boron b typical"
                       name="te_boron_b_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE boron b minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_boron_b_minimum_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE boron b minimum"
                       name="te_boron_b_minimum_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE boron b maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_boron_b_maximum_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE boron b maximum"
                       name="te_boron_b_maximum_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE cadmium cd typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_cadmium_cd_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE cadmium cd typical"
                       name="te_cadmium_cd_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE cadmium cd minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_cadmium_cd_minimum_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE cadmium cd minimum"
                       name="te_cadmium_cd_minimum_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE cadamium cd maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_cadamium_cd_maximum_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE cadamium cd maximum"
                       name="te_cadamium_cd_maximum_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE chromium cr typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_chromium_cr_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE chromium cr typical"
                       name="te_chromium_cr_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE chromium cr minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_chromium_cr_minimum_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE chromium cr minimum"
                       name="te_chromium_cr_minimum_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE chromium cr maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_chromium_cr_maximum_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE chromium cr maximum"
                       name="te_chromium_cr_maximum_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE copper cu typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_copper_cu_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE copper cu typical"
                       name="te_copper_cu_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE copper cu minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_copper_cu_minimum_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE copper cu minimum"
                       name="te_copper_cu_minimum_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE copper cu maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_copper_cu_maximum_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE copper cu maximum"
                       name="te_copper_cu_maximum_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE chlorine cl typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_chlorine_cl_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE chlorine cl typical"
                       name="te_chlorine_cl_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE chlorine cl minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_chlorine_cl_minimum_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE chlorine cl minimum"
                       name="te_chlorine_cl_minimum_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE chlorine cl maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_chlorine_cl_maximum_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE chlorine cl maximum"
                       name="te_chlorine_cl_maximum_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE fluorine f typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_fluorine_f_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE fluorine f typical"
                       name="te_fluorine_f_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE fluorine f minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_fluorine_f_minimum_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE fluorine f minimum"
                       name="te_fluorine_f_minimum_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE fluorine f maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_fluorine_f_maximum_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE fluorine f maximum"
                       name="te_fluorine_f_maximum_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE mercury hg typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_mercury_hg_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE mercury hg typical"
                       name="te_mercury_hg_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE mercury hg minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_mercury_hg_minimum_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE mercury hg minimum"
                       name="te_mercury_hg_minimum_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE mercury hg maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_mercury_hg_maximum_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE mercury hg maximum"
                       name="te_mercury_hg_maximum_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE molybdenum mo typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_molybdenum_mo_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE molybdenum mo typical"
                       name="te_molybdenum_mo_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE molybdenum mo minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_molybdenum_mo_minimum_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE molybdenum mo minimum"
                       name="te_molybdenum_mo_minimum_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE molybdenum mo maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_molybdenum_mo_maximum_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE molybdenum mo maximum"
                       name="te_molybdenum_mo_maximum_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE lead pb typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_lead_pb_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE lead pb typical"
                       name="te_lead_pb_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE lead pb minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_lead_pb_minimum_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE lead pb minimum"
                       name="te_lead_pb_minimum_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE lead pb maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_lead_pb_maximum_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE lead pb maximum"
                       name="te_lead_pb_maximum_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE phosphorus p typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_phosphorus_p_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE phosphorus p typical"
                       name="te_phosphorus_p_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE phosphorus p minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_phosphorus_p_minimum_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE phosphorus p minimum"
                       name="te_phosphorus_p_minimum_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE phosphorus p maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_phosphorus_p_maximum_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE phosphorus p maximum"
                       name="te_phosphorus_p_maximum_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE selenium se typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_selenium_se_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE selenium se typical"
                       name="te_selenium_se_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE selenium se minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_selenium_se_minimum_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE selenium se minimum"
                       name="te_selenium_se_minimum_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE selenium se maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_selenium_se_maximum_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE selenium se maximum"
                       name="te_selenium_se_maximum_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE vanadium v typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_vanadium_v_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE vanadium v typical"
                       name="te_vanadium_v_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE vanadium v min"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_vanadium_v_min_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE vanadium v min"
                       name="te_vanadium_v_min_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE vanadium v max"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_vanadium_v_max_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE vanadium v max"
                       name="te_vanadium_v_max_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE zinc zn typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_zinc_zn_typical_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE zinc zn typical"
                       name="te_zinc_zn_typical_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE zinc zn min"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_zinc_zn_min_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE zinc zn min"
                       name="te_zinc_zn_min_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="TE zinc zn max"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_zinc_zn_max_in_air_dried_coal}
         onChange={handleChange}
                       type="number"
                       placeholder="TE zinc zn max"
                       name="te_zinc_zn_max_in_air_dried_coal"
                       />
                   </FloatingLabel>
                 </Form.Group>
               </Row>

               <Row className="mb-3">
                 <h4 className="heading">Trace Elements, ppm Dried Basis</h4>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="te arsenic as typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_arsenic_as_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="te arsenic as typical"
                       name="te_arsenic_as_typical"
                      />
                   </FloatingLabel>
                 </Form.Group>

                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te arsenic as minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_arsenic_as_minimum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te arsenic as minimum"
                       name="te_arsenic_as_minimum"/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te arsenic as maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_arsenic_as_maximum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te arsenic as maximum"
                       name="te_arsenic_as_maximum"/>
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te antimony sb typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_antimony_sb_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Te antimony sb typical"
                       name="te_antimony_sb_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te antimony sb minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_antimony_sb_minimum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te antimony sb minimum"
                       name="te_antimony_sb_minimum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te antimony sb maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_antimony_sb_maximum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te antimony sb maximum"
                       name="te_antimony_sb_maximum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te beryllium be typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_beryllium_be_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Te beryllium be typical"
                       name="te_beryllium_be_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te beryllium be minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_beryllium_be_minimum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te beryllium be minimum"
                       name="te_beryllium_be_minimum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te beryllium be maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_beryllium_be_maximum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te beryllium be maximum"
                       name="te_beryllium_be_maximum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te boron b typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_boron_b_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Te boron b typical"
                       name="te_boron_b_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te boron b minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_boron_b_minimum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te boron b minimum"
                       name="te_boron_b_minimum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te boron b maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_boron_b_maximum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te boron b maximum"
                       name="te_boron_b_maximum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te cadmium cd typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_cadmium_cd_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Te cadmium cd typical"
                       name="te_cadmium_cd_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te cadmium cd minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_cadmium_cd_minimum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te cadmium cd minimum"
                       name="te_cadmium_cd_minimum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te cadmium cd maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_cadmium_cd_maximum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te cadmium cd maximum"
                       name="te_cadmium_cd_maximum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te chromium cr typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_chromium_cr_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Te chromium cr typical"
                       name="te_chromium_cr_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te chromium cr minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_chromium_cr_minimum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te chromium cr minimum"
                       name="te_chromium_cr_minimum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te chromium cr maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_chromium_cr_maximum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te chromium cr maximum"
                       name="te_chromium_cr_maximum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te copper cu typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_copper_cu_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Te copper cu typical"
                       name="te_copper_cu_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te copper cu minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_copper_cu_minimum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te copper cu minimum"
                       name="te_copper_cu_minimum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te copper cu maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_copper_cu_maximum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te copper cu maximum"
                       name="te_copper_cu_maximum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te chlorine cl typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_chlorine_cl_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Te chlorine cl typical"
                       name="te_chlorine_cl_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te chlorine cl minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_chlorine_cl_minimum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te chlorine cl minimum"
                       name="te_chlorine_cl_minimum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te chlorine cl maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_chlorine_cl_maximum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te chlorine cl maximum"
                       name="te_chlorine_cl_maximum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te fluorine f typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_fluorine_f_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Te fluorine f typical"
                       name="te_fluorine_f_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te fluorine f minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_fluorine_f_minimum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te fluorine f minimum"
                       name="te_fluorine_f_minimum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te fluorine f maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_fluorine_f_maximum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te fluorine f maximum"
                       name="te_fluorine_f_maximum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te mercury hg typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_mercury_hg_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Te mercury hg typical"
                       name="te_mercury_hg_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te mercury hg minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_mercury_hg_minimum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te mercury hg minimum"
                       name="te_mercury_hg_minimum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te mercury hg maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_mercury_hg_maximum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te mercury hg maximum"
                       name="te_mercury_hg_maximum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te molybdenum mo typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_molybdenum_mo_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Te molybdenum mo typical"
                       name="te_molybdenum_mo_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te molybdenum mo minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_molybdenum_mo_minimum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te molybdenum mo minimum"
                       name="te_molybdenum_mo_minimum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te molybdenum mo maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_molybdenum_mo_maximum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te molybdenum mo maximum"
                       name="te_molybdenum_mo_maximum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te lead pb typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_lead_pb_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Te lead pb typical"
                       name="te_lead_pb_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te lead pb minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_lead_pb_minimum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te lead pb minimum"
                       name="te_lead_pb_minimum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te lead pb maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_lead_pb_maximum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te lead pb maximum"
                       name="te_lead_pb_maximum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te phosphorus p typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_phosphorus_p_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Te phosphorus p typical"
                       name="te_phosphorus_p_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te phosphorus p minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_phosphorus_p_minimum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te phosphorus p minimum"
                       name="te_phosphorus_p_minimum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te phosphorus p maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_phosphorus_p_maximum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te phosphorus p maximum"
                       name="te_phosphorus_p_maximum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te selenium se typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_selenium_se_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Te selenium se typical"
                       name="te_selenium_se_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te selenium se minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_selenium_se_minimum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te selenium se minimum"
                       name="te_selenium_se_minimum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te selenium se maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_selenium_se_maximum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te selenium se maximum"
                       name="te_selenium_se_maximum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te vanadium v typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_vanadium_v_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Te vanadium v typical"
                       name="te_vanadium_v_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te vanadium v minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_vanadium_v_minimum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te vanadium v minimum"
                       name="te_vanadium_v_minimum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te vanadium v maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_vanadium_v_maximum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te vanadium v maximum"
                       name="te_vanadium_v_maximum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te zinc zn typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_zinc_zn_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Te zinc zn typical"
                       name="te_zinc_zn_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te zinc zn minimum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_zinc_zn_minimum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te zinc zn minimum"
                       name="te_zinc_zn_minimum"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Te zinc zn maximum"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.te_zinc_zn_maximum}
         onChange={handleChange}
                       type="number"
                       placeholder="Te zinc zn maximum"
                       name="te_zinc_zn_maximum"
                       />
                   </FloatingLabel>
                 </Form.Group>
               </Row>
               <Row className="mb-3">
                 <h4 className="heading">SIZING, %</h4>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Size 0 to 50mm typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.size_0_to_50mm_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Size 0 to 50mm typical"
                       name="size_0_to_50mm_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Size 0 to 50mm min"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.size_0_to_50mm_min}
         onChange={handleChange}
                       type="number"
                       placeholder="Size 0 to 50mm min"
                       name="size_0_to_50mm_min"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Size 0 to 50mm max"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.size_0_to_50mm_max}
         onChange={handleChange}
                       type="number"
                       placeholder="Size 0 to 50mm max"
                       name="size_0_to_50mm_max"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Above 0 to 50mm typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.above_0_to_50mm_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Above 0 to 50mm typical"
                       name="above_0_to_50mm_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Above 0 to 50mm min"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.above_0_to_50mm_min}
         onChange={handleChange}
                       type="number"
                       placeholder="Above 0 to 50mm min"
                       name="above_0_to_50mm_min"
                       />
                   </FloatingLabel>
                 </Form.Group>{" "}
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Above 0 to 50mm max"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.above_0_to_50mm_max}
         onChange={handleChange}
                       type="number"
                       placeholder="Above 0 to 50mm max"
                       name="above_0_to_50mm_max"
                       />
                   </FloatingLabel>
                 </Form.Group>{" "}
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Under 2mm typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.under_2mm_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Under 2mm typical"
                       name="under_2mm_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>{" "}
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Under 2mm min"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.under_2mm_min}
         onChange={handleChange}
                       type="number"
                       placeholder="Under 2mm min"
                       name="under_2mm_min"
                       />
                   </FloatingLabel>
                 </Form.Group>{" "}
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Under 2mm max"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.under_2mm_max}
         onChange={handleChange}
                       type="number"
                       placeholder="Under 2mm max"
                       name="under_2mm_max"
                       />
                   </FloatingLabel>
                 </Form.Group>{" "}
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Size above 2mm typical"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.size_above_2mm_typical}
         onChange={handleChange}
                       type="number"
                       placeholder="Size above 2mm typical"
                       name="size_above_2mm_typical"
                       />
                   </FloatingLabel>
                 </Form.Group>{" "}
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Size above 2mm min"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.size_above_2mm_min}
         onChange={handleChange}
                       type="number"
                       placeholder="Size above 2mm min"
                       name="size_above_2mm_min"
                       />
                   </FloatingLabel>
                 </Form.Group>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingInput"
                     label="Size above 2mm max"
                     className="mb-3"
                   >
                     <Form.Control defaultValue={data1.size_above_2mm_max}
         onChange={handleChange}
                       type="number"
                       placeholder="Size above 2mm max"
                       name="size_above_2mm_max" />
                   </FloatingLabel>
                 </Form.Group>
               </Row>
               <Row className="mb-3">
                 <h4 className="heading">Testing Method</h4>
                 <Form.Group as={Col} md="6">
                   <FloatingLabel
                     controlId="floatingSelect"
                     className="dropDown"
                     label="Testing Method"
                   >
                     <Form.Select
                       aria-label="Floating label select example"
                       name="testing_method"
                       placeholder="Testing method"
                       defaultValue={data1.size_above_2mm_max}
         onChange={handleChange}
                     >
                       <option defaultValue="">Select </option>
                       <option defaultValue="According to ASTM Standard Method Unless Otherwise Stated">
                         According to ASTM Standard Method Unless Otherwise Stated
                       </option>
                       <option defaultValue="According to ISO Methods">
                         According to ISO Methods
                       </option>
                       <option defaultValue="According to ISO Methods Except HGI, Trace Elements and Ash Analysis to ASTM">
                         According to ISO Methods Except HGI, Trace Elements and Ash
                         Analysis to ASTM
                       </option>
                       <option defaultValue="According to ISO Methods Except HGI to ASTM">
                         According to ISO Methods Except HGI to ASTM
                       </option>
                       <option defaultValue="According to ISO Methods, Except HGI and Ash Analysis to ASTM">
                         According to ISO Methods, Except HGI and Ash Analysis to
                         ASTM
                       </option>
                       <option defaultValue="According to ISO Methods, Except HGI as per ASTM Test Method">
                         According to ISO Methods, Except HGI as per ASTM Test Method
                       </option>
                     </Form.Select>
                   </FloatingLabel>
                 </Form.Group>
               </Row>
               <p className="edit-btn">
               <input
      type="submit"
      className="account-save"
      onClick={handleSaveEdit}
      value="Save"
    />
         <button className="btn btn-primary" onClick={handleCancelEdit}>
           Cancel
         </button>
       </p>
                   </div>
      ):(

      <div className="tasks ">


    <div className="edit-delete">
                          <span id="edit" onClick={handleEditClick}>
                            Edit
                          </span>
                          <span>
                            <Link to={"/product/product-list"}> Cancel</Link>
                          </span>
                        </div>
        <div className="col-md-12 product-heading" id="ax.account_details">
          <h4 className="heading">Product Details</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
          <tbody>
            <tr>
            <td id="td-right">
                <span>Product No</span>
                </td><td>
                {data1.product_no}
              </td>
              <td id="td-right">
                                  <span>Locked</span>
                                </td>
                                <td> <input
                    type='checkbox'
                    name='locked'
                    checked={lockedOne} // Use the state variable to determine if the checkbox is checked
                    onChange={handleCheckboxLocked} // Handle the change event
                    className='mx-3' disabled={isButtonEnabled}
                  /></td>

            </tr> <tr>
            <td id="td-right">
                <span>currency</span>
                </td><td>
                {data1.currency}
              </td>
              <td id="td-right">
                <span>Product Family	</span>
                </td><td>
                {data1.product_family}
              </td>

            </tr> <tr>
            <td id="td-right">
                <span>exchange rate</span>
                </td><td>
                {data1.exchange_rate}
              </td>
              <td id="td-right">
                <span>Product Code</span>
                </td><td>
                {data1.product_code}
              </td>

            </tr> <tr>
            <td id="td-right">
                <span>product master</span>
                </td><td>
                <td><Link to={  "/productmaster/product-detail/" + data1.product_master_id} >{data1.product_master}</Link></td>

              </td>
              <td id="td-right">
                <span>product description</span>
                </td><td>
                {data1.product_description}
              </td>

            </tr> <tr>
            <td id="td-right">
                <span>supplier</span>
                </td><td>

                <td><Link to={  "/accounts/" + data1.account_id} >{data1.supplier}</Link></td>

              </td>
              <td id="td-right">
                <span></span>
                </td><td>
                {}
              </td>

            </tr> <tr>

              <td id="td-right">
                <span></span>
                </td><td id="td-right">
              </td>
              <td id="td-right">
                <span>Active</span>
                </td><td>
                {data1.active}
              </td>

            </tr> <tr>
            <td id="td-right">
                <span>product name</span>
                </td><td>
                {data1.product_name}
              </td>
              <td id="td-right">
                <span className="a-a">
                Product available for short time confirm	 {" "}
                                                <Tooltip text={<span>can confirm for short time/ Certain Product can not confirm for long time</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                </td><td>
                {data1.product_available_for_short_term_confirm}
              </td>

            </tr> <tr>
            <td id="td-right">
                <span>purchase contract</span>
                </td><td>
                {data1.purchase_contract}
              </td>
              <td id="td-right">
                
                <span className="a-a">
                Order Create {" "}
                                                <Tooltip text={<span>True = Relevant Order product exists <br/>False = Order Product Not exists.</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                </td><td>
                {data1.order_created}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span> Buyer Name	</span>
                </td><td>
                {data1.buyer_name}
              </td>
              <td id="td-right">
                <span></span>
                </td><td>

              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span> Buyer Contract		</span>
                </td><td>
                <Link to={`/contract/contract-details/${data1.contract_id}`}>{data1.buyer_contract}</Link>
              </td>
              <td id="td-right">
                <span></span>
                </td><td>

              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span> Contract Shipment		</span>
                </td>
                <td>
                <Link to ={`/shipment/contract-shipment-details/${data1.contract_shipment_id}`}>{data1.contract_shipment}</Link>
              </td>
              <td id="td-right">
                <span></span>
                </td><td>

              </td>

            </tr>

          </tbody>
        </table>
        <div className="col-md-12 product-heading" id="ax.account_details">
          <h4 className="heading">Product Availability</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
          <tbody>
            <tr>
            <td id="td-right">
                <span>Quantity (MT)</span>
                </td><td>
                {data1.quantity_MT}
              </td>
              <td id="td-right">
                <span>Status</span>
                </td><td>
                {data1.product_status}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Year</span>
                </td><td>
                {data1.product_year}
              </td>
              <td id="td-right">
                <span>Laycan From Date	</span>
                </td><td>
                {data1.laycan_from_date}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Month</span>
                </td><td>
                {data1.product_month}
              </td>
              <td id="td-right">
                <span>Laycan To Date	</span>
                </td><td>
                {data1.laycan_to_date}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Price Type	</span>
                </td><td>
                {data1.price_type}
              </td>
              <td id="td-right">
                <span>Port of Loading	</span>
                </td><td>
                {data1.port_of_loading}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Price</span>
                </td><td>
                {renderShipmentQty(data1.price)}
              </td>
              <td id="td-right">
                <span>Shipping Term	</span>
                </td><td>
                {data1.shipping_term}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Price Basis GAR	</span>
                </td><td>
                {renderShipmentQty(data1.price_basis_GAR)}
              </td>
              <td id="td-right">
                <span>Vessel Type	</span>
                </td><td>
                {data1.vessel_type}
              </td>

            </tr>
            </tbody>
            </table>

            <div className="col-md-12 product-heading" id="ax.account_details">
          <h4 className="heading">%As Received Basis</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
          <tbody>
            <tr>
            <td id="td-right">
                <span>Total Moisture Typical	</span>
                </td><td>
                {renderShipmentQty(data1.total_moisture_typical)}
              </td>
              <td id="td-right">
                <span>Sulphur Typical ARB	</span>
                </td><td>
                {renderShipmentQty(data1.sulphur_typical_arb)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Total Moisture Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.total_moisture_minimum)}
              </td>
              <td id="td-right">
                <span>Sulphur Minimum ARB	</span>
                </td><td>
                {renderShipmentQty(data1.sulphur_minimum_arb)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Total Moisture Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.total_moisture_maximum)}
              </td>
              <td id="td-right">
                <span>Sulphur Maximum ARB	</span>
                </td><td>
                {renderShipmentQty(data1.sulphur_maximum_arb)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Ash Typical ARB	</span>
                </td><td>
                {renderShipmentQty(data1.ash_typical_arb)}
              </td>
              <td id="td-right">
                <span>Volatile Matter Typical ARB	</span>
                </td><td>
                {renderShipmentQty(data1.volatile_matter_typical_arb)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Ash Minimum ARB	</span>
                </td><td>
                {renderShipmentQty(data1.ash_minimum_arb)}
              </td>
              <td id="td-right">
                <span>Volatile Matter Minimum ARB	</span>
                </td><td>
                {renderShipmentQty(data1.volatile_matter_minimum_arb)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Ash Maximum ARB	</span>
                </td><td>
                {renderShipmentQty(data1.ash_maximum_arb)}
              </td>
              <td id="td-right">
                <span>Volatile Matter Maximum ARB	</span>
                </td><td>
                {renderShipmentQty(data1.volatile_matter_maximum_arb)}
              </td>

            </tr>
            </tbody>
            </table>

            <div className="col-md-12 product-heading" id="ax.account_details">
          <h4 className="heading">Proximate Analysis %Air Dried Basis</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
          <tbody>
            <tr>
            <td id="td-right">
                <span>Moisture Typical		</span>
                </td><td>
                {renderShipmentQty(data1.moisture_typical)}
              </td>
              <td id="td-right">
                <span>Volatile Matter Typical		</span>
                </td><td>
                {renderShipmentQty(data1.volatile_matter_typical)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Moisture Minimum		</span>
                </td><td>
                {renderShipmentQty(data1.moisture_minimum)}
              </td>
              <td id="td-right">
                <span>Volatile Matter Minimum		</span>
                </td><td>
                {renderShipmentQty(data1.volatile_matter_minimum)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Moisture Maximum		</span>
                </td><td>
                {renderShipmentQty(data1.moisture_maximum)}
              </td>
              <td id="td-right">
                <span>Volatile Matter Maximum		</span>
                </td><td>
                {renderShipmentQty(data1.volatile_matter_maximum)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Ash Typical		</span>
                </td><td>
                {renderShipmentQty(data1.ash_typical)}
              </td>
              <td id="td-right">
                <span>Fixed Carbon By Difference		</span>
                </td><td>
                {renderShipmentQty(data1.fixed_carbon_by_difference)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Ash Minimum		</span>
                </td><td>
                {renderShipmentQty(data1.ash_minimum)}
              </td>
              <td id="td-right">
                <span>Fixed Carbon Typical		</span>
                </td><td>
                {renderShipmentQty(data1.fixed_carbon_typical)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Ash Maximum		</span>
                </td><td>
                {renderShipmentQty(data1.ash_maximum)}
              </td>
              <td id="td-right">
                <span>Fixed Carbon Minimum		</span>
                </td><td>
                {renderShipmentQty(data1.fixed_carbon_minimum)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>	</span>
                </td><td>

              </td>
              <td id="td-right">
                <span>Fixed Carbon Maximum		</span>
                </td><td>
                {renderShipmentQty(data1.fixed_carbon_maximum)}
              </td>

            </tr>
          </tbody>
          </table>


          <div className="col-md-12 product-heading" id="ax.account_details">
          <h4 className="heading">CALORIFIC VALUE, kcal / kg</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
          <tbody>
            <tr>
            <td id="td-right">
                <span>Gross Air Dried Typical	</span>
                </td><td>
                {renderShipmentQty(data1.gross_air_dried_typical)}
              </td>
              <td id="td-right">
                <span>Net As Received Typical			</span>
                </td><td>
                {renderShipmentQty(data1.net_as_received_typical)}
              </td>

            </tr>

            <tr>
            <td id="td-right">
                <span>Gross Air Dried Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.gross_air_dried_min)}
              </td>
              <td id="td-right">
                <span>Net as received Minimum			</span>
                </td><td>
                {renderShipmentQty(data1.net_as_received_min)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Gross Air Dried Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.gross_air_dried_max)}
              </td>
              <td id="td-right">
                <span>Net As Received Maximum			</span>
                </td><td>
                {renderShipmentQty(data1.net_as_received_max)}
              </td>

            </tr>

            <tr>
            <td id="td-right">
                <span>Gross As Received Typical	</span>
                </td><td>
                {renderShipmentQty(data1.gross_as_received_typical)}
              </td>
              <td id="td-right">
                <span>		</span>
                </td><td>

              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Gross As Received Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.gross_as_received_min)}
              </td>
              <td id="td-right">
                <span>		</span>
                </td><td>

              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Gross As Received Maximum		</span>
                </td><td>
                {renderShipmentQty(data1.gross_as_received_max)}
              </td>
              <td id="td-right">
                <span>		</span>
                </td><td>

              </td>

            </tr>
            </tbody>
            </table>



          <div className="col-md-12 product-heading" id="ax.account_details">
          <h4 className="heading">CALORIFIC VALUE, kcal / kg</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
          <tbody>
            <tr>
            <td id="td-right">
                <span>HGI Typical		</span>
                </td><td>
                {renderShipmentQty(data1.hgi_typical)}
              </td>
              <td id="td-right">
                <span></span>
                </td><td>

              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>HGI Minimum		</span>
                </td><td>
                {renderShipmentQty(data1.hgi_min)}
              </td>
              <td id="td-right">
                <span></span>
                </td><td>

              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>HGI Maximum		</span>
                </td><td>
                {renderShipmentQty(data1.hgi_max)}
              </td>
              <td id="td-right">
                <span></span>
                </td><td>

              </td>

            </tr>
            </tbody>
            </table>




          <div className="col-md-12 product-heading" id="ax.account_details">
          <h4 className="heading">CALORIFIC VALUE, kcal / kg</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
          <tbody>
            <tr>
            <td id="td-right">
                <span>Carbon Typical		</span>
                </td><td>
                {renderShipmentQty(data1.carbon_typical)}
              </td>
              <td id="td-right">
                <span>Sulphur Typical				</span>
                </td><td>
                {renderShipmentQty(data1.sulphur_typical)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Carbon Minimum		</span>
                </td><td>
                {renderShipmentQty(data1.carbon_min)}
              </td>
              <td id="td-right">
                <span>Sulphur Minimum				</span>
                </td><td>
                {renderShipmentQty(data1.sulphur_min)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Carbon Maximum		</span>
                </td><td>
                {renderShipmentQty(data1.carbon_max)}
              </td>
              <td id="td-right">
                <span>Sulphur Maximum				</span>
                </td><td>
                {renderShipmentQty(data1.sulphur_max)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Hydrogen Typical		</span>
                </td><td>
                {renderShipmentQty(data1.hydrogen_typical)}
              </td>
              <td id="td-right">
                <span>Oxygen Typical				</span>
                </td><td>
                {renderShipmentQty(data1.oxygen_typical)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Hydrogen Minimum		</span>
                </td><td>
                {renderShipmentQty(data1.hydrogen_min)}
              </td>
              <td id="td-right">
                <span>Oxygen Minimum				</span>
                </td><td>
                {renderShipmentQty(data1.oxygen_min)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Hydrogen Maximum		</span>
                </td><td>
                {renderShipmentQty(data1.hydrogen_max)}
              </td>
              <td id="td-right">
                <span>Oxygen Maximum				</span>
                </td><td>
                {renderShipmentQty(data1.oxygen_max)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Nitrogen Typical		</span>
                </td><td>
                {renderShipmentQty(data1.nitrogen_typical)}
              </td>
              <td id="td-right">
                <span></span>
                </td><td>

              </td>

            </tr>

            <tr>
            <td id="td-right">
                <span>Nitrogen Minimum			</span>
                </td><td>
                {renderShipmentQty(data1.nitrogen_min)}
              </td>
              <td id="td-right">
                <span></span>
                </td><td>

              </td>

            </tr>

            <tr>
            <td id="td-right">
                <span>Nitrogen Maximum			</span>
                </td><td>
                {renderShipmentQty(data1.nitrogen_max)}
              </td>
              <td id="td-right">
                <span></span>
                </td><td>

              </td>

            </tr>


</tbody>
</table>



<div className="col-md-12 product-heading" id="ax.account_details">
          <h4 className="heading">SULPHUR, %air dried basis</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
          <tbody>
            <tr>
            <td id="td-right">
                <span>Sulphur Typical ADB		</span>
                </td><td>
                {renderShipmentQty(data1.sulphur_typical_adb)}
              </td>
              <td id="td-right">
                <span>Sulphur Minimum ADB					</span>
                </td><td>
                {renderShipmentQty(data1.sulphur_min_adb)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Sulphur Maximum ADB	</span>
                </td><td>
                {renderShipmentQty(data1.carbon_typical)}
              </td>
              <td id="td-right">
                <span> 			</span>
                </td><td>

              </td>

            </tr>
            </tbody>
            </table>


            <div className="col-md-12 product-heading" id="ax.account_details">
          <h4 className="heading">ASH FUSION TEMPERATURE, Deg(C) Reducing</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
          <tbody>
            <tr>
            <td id="td-right">
                <span>Initial Deformation Typical			</span>
                </td><td>
                {renderShipmentQty(data1.initial_typical)}
              </td>
              <td id="td-right">
                <span>Hemispherical Typical						</span>
                </td><td>
                {renderShipmentQty(data1.hemispherical_typical)}
              </td>

            </tr>

            <tr>
            <td id="td-right">
                <span>Initial Deformation Minimum			</span>
                </td><td>
                {renderShipmentQty(data1.initial_minimum)}
              </td>
              <td id="td-right">
                <span>Hemispherical Minimum						</span>
                </td><td>
                {renderShipmentQty(data1.hemispherical_minimum)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Initial Deformation Maximum			</span>
                </td><td>
                {renderShipmentQty(data1.initial_maximum)}
              </td>
              <td id="td-right">
                <span>Hemispherical Maximum						</span>
                </td><td>
                {renderShipmentQty(data1.hemispherical_maximum)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Spherical Typical			</span>
                </td><td>
                {renderShipmentQty(data1.spherical_typical)}
              </td>
              <td id="td-right">
                <span>Flow Typical						</span>
                </td><td>
                {renderShipmentQty(data1.flow_typical)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Spherical Minimum			</span>
                </td><td>
                {renderShipmentQty(data1.spherical_minimum)}
              </td>
              <td id="td-right">
                <span>Flow Minimum						</span>
                </td><td>
                {renderShipmentQty(data1.flow_minimum)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Spherical Maximum			</span>
                </td><td>
                {renderShipmentQty(data1.spherical_maximum)}
              </td>
              <td id="td-right">
                <span>Flow Maximum						</span>
                </td><td>
                {renderShipmentQty(data1.flow_maximum)}
              </td>

            </tr>
            </tbody>
            </table>


            <div className="col-md-12 product-heading" id="ax.account_details">
          <h4 className="heading">ASH FUSION TEMPRATURE , Deg(C) Oxidizing
</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
          <tbody>
            <tr>
            <td id="td-right">
                <span>Oxidizing Initial Deformation Typical				</span>
                </td><td>
                {renderShipmentQty(data1.oxidising_initial_typical)}
              </td>
              <td id="td-right">
                <span>Oxidizing Hemisperical Typical</span>
                </td><td>
                {renderShipmentQty(data1.oxidising_hemispherical_typical)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Oxidizing Initial Deformation Minimum				</span>
                </td><td>
                {renderShipmentQty(data1.oxidising_initial_minimum)}
              </td>
              <td id="td-right">
                <span>Oxidizing Hemisperical Minimum</span>
                </td><td>
                {renderShipmentQty(data1.oxidising_hemispherical_minimum)}
              </td>

            </tr>

             <tr>
            <td id="td-right">
                <span>Oxidizing Initial Deformation Maximum				</span>
                </td><td>
                {renderShipmentQty(data1.oxidising_initial_maximum)}
              </td>
              <td id="td-right">
                <span>Oxidizing Hemisperical Maximum</span>
                </td><td>
                {renderShipmentQty(data1.oxidising_hemispherical_maximum)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Oxidizing Spherical Typical				</span>
                </td><td>
                {renderShipmentQty(data1.oxidising_spherical_typical)}
              </td>
              <td id="td-right">
                <span>Oxidizing Flow Typical</span>
                </td><td>
                {renderShipmentQty(data1.oxidising_flow_typical)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Oxidizing Spherical Minimum				</span>
                </td><td>
                {renderShipmentQty(data1.oxidising_spherical_minimum)}
              </td>
              <td id="td-right">
                <span>Oxidizing Flow Minimum</span>
                </td><td>
                {renderShipmentQty(data1.oxidising_flow_minimum)}
              </td>

            </tr>

            <tr>
            <td id="td-right">
                <span>Oxidizing Spherical Maximum			</span>
                </td><td>
                {renderShipmentQty(data1.oxidising_spherical_maximum)}
              </td>
              <td id="td-right">
                <span>Oxidizing Flow Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.oxidising_flow_maximum)}
              </td>

            </tr>
            </tbody>
            </table>




            <div className="col-md-12 product-heading" id="ax.account_details">
          <h4 className="heading">ASH ANALYSIS, %dry basis in ash
</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
          <tbody>
            <tr>
            <td id="td-right">
                <span>SiO2 Typical					</span>
                </td><td>
                {renderShipmentQty(data1.SiO2_Typical)}
              </td>
              <td id="td-right">
                <span>TiO2 Typical	</span>
                </td><td>
                {renderShipmentQty(data1.TiO2_Typical)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>SiO2 Minimum					</span>
                </td><td>
                {renderShipmentQty(data1.SiO2_Minimum)}
              </td>
              <td id="td-right">
                <span>TiO2 Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.TiO2_Minimum)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>SiO2 Maximum					</span>
                </td><td>
                {renderShipmentQty(data1.SiO2_Maximum)}
              </td>
              <td id="td-right">
                <span>TiO2 Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.TiO2_Maximum)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>AI2O3 Typical					</span>
                </td><td>
                {renderShipmentQty(data1.Al2O3_Typical)}
              </td>
              <td id="td-right">
                <span>NA2O Typical	</span>
                </td><td>
                {renderShipmentQty(data1.Na2O_Typical)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>AI2O3 Minimum					</span>
                </td><td>
                {renderShipmentQty(data1.Al2O3_Minimum)}
              </td>
              <td id="td-right">
                <span>NA2O Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.Na2O_Minimum)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>AI2O3 Maximum					</span>
                </td><td>
                {renderShipmentQty(data1.Al2O3_Maximum)}
              </td>
              <td id="td-right">
                <span>NA2O Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.Na2O_Maximum)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Fe2O3 Typical					</span>
                </td><td>
                {renderShipmentQty(data1.Fe2O3_Typical)}
              </td>
              <td id="td-right">
                <span>K2O Typical	</span>
                </td><td>
                {renderShipmentQty(data1.K2O_Typical)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Fe2O3 Minimum					</span>
                </td><td>
                {renderShipmentQty(data1.Fe2O3_Minimum)}
              </td>
              <td id="td-right">
                <span>K2O Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.K2O_Minimum)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Fe2O3 Maximum					</span>
                </td><td>
                {renderShipmentQty(data1.Fe2O3_Maximum)}
              </td>
              <td id="td-right">
                <span>K2O Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.K2O_Maximum)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span> 			</span>
                </td><td>

              </td>
              <td id="td-right">
                <span>P2O5 Typical	</span>
                </td><td>
                {renderShipmentQty(data1.P2O5_Typical)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>CaO Minimum					</span>
                </td><td>
                {renderShipmentQty(data1.CaO_Minimum)}
              </td>
              <td id="td-right">
                <span>P2O5 Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.P2O5_Minimum)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>CaO Maximum					</span>
                </td><td>
                {renderShipmentQty(data1.CaO_Maximum)}
              </td>
              <td id="td-right">
                <span>P2O5 Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.P2O5_Maximum)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>MgO Typical					</span>
                </td><td>
                {renderShipmentQty(data1.MgO_Typical)}
              </td>
              <td id="td-right">
                <span>SO3 Typical	</span>
                </td><td>
                {renderShipmentQty(data1.SO3_Typical)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>MgO Minimum					</span>
                </td><td>
                {renderShipmentQty(data1.MgO_Minimum)}
              </td>
              <td id="td-right">
                <span>SO3 Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.SO3_Minimum)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>MgO Maximum					</span>
                </td><td>
                {renderShipmentQty(data1.MgO_Maximum)}
              </td>
              <td id="td-right">
                <span>SO3 Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.SO3_Maximum)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Mn3O4 Typical					</span>
                </td><td>
                {renderShipmentQty(data1.Mn3O4_Typical)}
              </td>
              <td id="td-right">
                <span></span>
                </td><td>

              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Mn3O4 Minimum					</span>
                </td><td>
                {renderShipmentQty(data1.Mn3O4_Minimum)}
              </td>
              <td id="td-right">
                <span></span>
                </td><td>

              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Mn3O4 Maximum					</span>
                </td><td>
                {renderShipmentQty(data1.Mn3O4_Maximum)}
              </td>
              <td id="td-right">
                <span></span>
                </td><td>

              </td>

            </tr>
            </tbody>
            </table>



            <div className="col-md-12 product-heading" id="ax.account_details">
          <h4 className="heading">Trace Elements, ppm Air Dried in Coal
</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
          <tbody>
            <tr>
            <td id="td-right">
                <span>TE Arsenic As Typical						</span>
                </td><td>
                {renderShipmentQty(data1.te_arsenic_as_typical_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Fluorine F Typical	</span>
                </td><td>
                {renderShipmentQty(data1.te_fluorine_f_typical_in_air_dried_coal)}
              </td>

            </tr>

            <tr>
            <td id="td-right">
                <span>TE Arsenic As Minimum						</span>
                </td><td>
                {renderShipmentQty(data1.te_arsenic_as_minimum)}
              </td>
              <td id="td-right">
                <span>TE Fluorine F Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.te_fluorine_f_minimum)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Arsenic As Maximum						</span>
                </td><td>
                {renderShipmentQty(data1.SiO2_Typical)}
              </td>
              <td id="td-right">
                <span>TE Fluorine F Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.te_fluorine_f_maximum)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Antimony Sb Typical						</span>
                </td><td>
                {renderShipmentQty(data1.te_antimony_sb_typical_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Mercury Hg Typical	</span>
                </td><td>
                {renderShipmentQty(data1.te_mercury_hg_typical_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Antimony Sb Minimum						</span>
                </td><td>
                {renderShipmentQty(data1.te_antimony_sb_minimum_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Mercury Hg Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.te_mercury_hg_minimum_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Antimony Sb Maximum						</span>
                </td><td>
                {renderShipmentQty(data1.te_antimony_sb_maximum_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Mercury Hg Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.te_mercury_hg_maximum_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Beryllium Be Typical						</span>
                </td><td>
                {renderShipmentQty(data1.te_beryllium_be_typical_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Molybdenum Mo Typical	</span>
                </td><td>
                {renderShipmentQty(data1.te_molybdenum_mo_typical_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Beryllium Be Minimum						</span>
                </td><td>
                {renderShipmentQty(data1.te_beryllium_be_minimum_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Molybdenum Mo Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.te_molybdenum_mo_minimum_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Beryllium Be Maximum						</span>
                </td><td>
                {renderShipmentQty(data1.te_beryllium_be_maximum_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Molybdenum Mo Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.te_molybdenum_mo_maximum_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Boron B Typical						</span>
                </td><td>
                {renderShipmentQty(data1.te_boron_b_typical)}
              </td>
              <td id="td-right">
                <span>TE Lead Pb Typical	</span>
                </td><td>
                {renderShipmentQty(data1.te_lead_pb_typical)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Boron B Minimum						</span>
                </td><td>
                {renderShipmentQty(data1.te_boron_b_minimum)}
              </td>
              <td id="td-right">
                <span>TE Lead Pb Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.te_lead_pb_minimum)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Boron B Maximum						</span>
                </td><td>
                {renderShipmentQty(data1.te_boron_b_maximum)}
              </td>
              <td id="td-right">
                <span>TE Lead Pb Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.te_lead_pb_maximum)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Cadmium Cd Typical						</span>
                </td><td>
                {renderShipmentQty(data1.te_cadmium_cd_typical_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Phosporous P Typical	</span>
                </td><td>
                {renderShipmentQty(data1.TiO2_Typical)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Cadmium Cd Minimum						</span>
                </td><td>
                {renderShipmentQty(data1.te_cadmium_cd_minimum_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Phosporous P Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.TiO2_Typical)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Cadmium Cd Maximum						</span>
                </td><td>
                {renderShipmentQty(data1.te_cadmium_cd_maximum_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Phosporous P Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.TiO2_Typical)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Chromium Cr Typical						</span>
                </td><td>
                {renderShipmentQty(data1.te_chromium_cr_typical_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Selenium Se Typical	</span>
                </td><td>
                {renderShipmentQty(data1.te_selenium_se_typical_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Chromium Cr Minimum						</span>
                </td><td>
                {renderShipmentQty(data1.te_chromium_cr_minimum_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Selenium Se Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.te_selenium_se_minimum_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Chromium Cr Maximum						</span>
                </td><td>
                {renderShipmentQty(data1.te_chromium_cr_maximum_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Selenium Se Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.te_selenium_se_maximum_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Copper Cu Typical						</span>
                </td><td>
                {renderShipmentQty(data1.te_copper_cu_typical)}
              </td>
              <td id="td-right">
                <span>TE Vanadium V Typical	</span>
                </td><td>
                {renderShipmentQty(data1.te_vanadium_v_typical)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Copper Cu Minimum						</span>
                </td><td>
                {renderShipmentQty(data1.te_copper_cu_minimum)}
              </td>
              <td id="td-right">
                <span>TE Vanadium V Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.te_vanadium_v_min)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Copper Cu Maximum						</span>
                </td><td>
                {renderShipmentQty(data1.te_copper_cu_maximum)}
              </td>
              <td id="td-right">
                <span>TE Vanadium V Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.te_vanadium_v_max)}
              </td>

            </tr>

            <tr>
            <td id="td-right">
                <span>TE Chlorine Cl Typical					</span>
                </td><td>
                {renderShipmentQty(data1.te_chlorine_cl_typical_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Zinc Zn Typical	</span>
                </td><td>
                {renderShipmentQty(data1.te_zinc_zn_typical_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Chlorine Cl Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.te_chlorine_cl_minimum_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Zinc Zn Minimum		</span>
                </td><td>
                {renderShipmentQty(data1.te_zinc_zn_minimum_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Chlorine Cl Maximum		</span>
                </td><td>
                {renderShipmentQty(data1.te_chlorine_cl_maximum_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Zinc Zn Maximum		</span>
                </td><td>
                {renderShipmentQty(data1.te_zinc_zn_maximum_in_air_dried_coal)}
              </td>

            </tr>

      </tbody>
      </table>




      <div className="col-md-12 product-heading" id="ax.account_details">
          <h4 className="heading">Trace Elements, ppm Air Dried in Coal
</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
          <tbody>
            <tr>
            <td id="td-right">
                <span>TE Arsenic As Typical	</span>
                </td><td>
                {renderShipmentQty(data1.te_arsenic_as_typical)}
              </td>
              <td id="td-right">
                <span>TE Fluorine F Typical	</span>
                </td><td>
                {renderShipmentQty(data1.te_fluorine_f_typical_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Arsenic As Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.te_arsenic_as_minimum)}
              </td>
              <td id="td-right">
                <span>TE Fluorine F Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.te_fluorine_f_minimum_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Arsenic As Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.te_arsenic_as_maximum)}
              </td>
              <td id="td-right">
                <span>TE Fluorine F Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.te_fluorine_f_maximum_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Antimony Sb Typical	</span>
                </td><td>
                {renderShipmentQty(data1.te_antimony_sb_typical)}
              </td>
              <td id="td-right">
                <span>TE Mercury Hg Typical	</span>
                </td><td>
                {renderShipmentQty(data1.te_mercury_hg_typical)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Antimony Sb Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.te_antimony_sb_minimum)}
              </td>
              <td id="td-right">
                <span>TE Mercury Hg Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.te_mercury_hg_minimum)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Antimony Sb Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.te_antimony_sb_maximum)}
              </td>
              <td id="td-right">
                <span>TE Mercury Hg Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.te_mercury_hg_maximum)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Beryllium Be Typical	</span>
                </td><td>
                {renderShipmentQty(data1.te_mercury_hg_typical)}
              </td>
              <td id="td-right">
                <span>TE Molybdenum Mo Typical	</span>
                </td><td>
                {renderShipmentQty(data1.te_molybdenum_mo_typical)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Beryllium Be Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.te_molybdenum_mo_minimum)}
              </td>
              <td id="td-right">
                <span>TE Molybdenum Mo Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.te_mercury_hg_minimum)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Beryllium Be Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.te_molybdenum_mo_maximum)}
              </td>
              <td id="td-right">
                <span>TE Molybdenum Mo Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.te_mercury_hg_maximum)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Boron B Typical	</span>
                </td><td>
                {renderShipmentQty(data1.te_boron_b_typical_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Lead Pb Typical	</span>
                </td><td>
                {renderShipmentQty(data1.te_lead_pb_typical_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Boron B Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.te_boron_b_minimum_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Lead Pb Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.te_lead_pb_minimum_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Boron B Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.te_boron_b_maximum_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Lead Pb Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.te_lead_pb_maximum_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Cadmium Cd Typical	</span>
                </td><td>
                {renderShipmentQty(data1.te_cadmium_cd_typical_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Phosporous P Typical	</span>
                </td><td>
                {renderShipmentQty(data1.te_phosphorus_p_typical_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Cadmium Cd Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.te_cadmium_cd_minimum_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Phosporous P Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.te_phosphorus_p_minimum_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Cadmium Cd Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.te_cadmium_cd_maximum_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Phosporous P Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.te_phosphorus_p_maximum_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Chromium Cr Typical	</span>
                </td><td>
                {renderShipmentQty(data1.te_selenium_se_typical_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Selenium Se Typical	</span>
                </td><td>
                {renderShipmentQty(data1.te_fluorine_f_typical_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Chromium Cr Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.te_chromium_cr_minimum_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Selenium Se Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.te_selenium_se_minimum_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Chromium Cr Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.te_chromium_cr_maximum_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Selenium Se Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.te_selenium_se_maximum_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Copper Cu Typical	</span>
                </td><td>
                {renderShipmentQty(data1.te_copper_cu_typical_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Vanadium V Typical	</span>
                </td><td>
                {renderShipmentQty(data1.te_vanadium_v_typical_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Copper Cu Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.te_copper_cu_minimum_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Vanadium V Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.te_vanadium_v_minimum_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Copper Cu Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.te_copper_cu_maximum_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Vanadium V Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.te_vanadium_v_maximum_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Chlorine Cl Typical	</span>
                </td><td>
                {renderShipmentQty(data1.te_chlorine_cl_typical_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Zinc Zn Typical	</span>
                </td><td>
                {renderShipmentQty(data1.te_zinc_zn_typical_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Chlorine Cl Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.te_chlorine_cl_minimum_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Zinc Zn Minimum	</span>
                </td><td>
                {renderShipmentQty(data1.te_zinc_zn_minimum_in_air_dried_coal)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>TE Chlorine Cl Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.te_chlorine_cl_maximum_in_air_dried_coal)}
              </td>
              <td id="td-right">
                <span>TE Zinc Zn Maximum	</span>
                </td><td>
                {renderShipmentQty(data1.te_zinc_zn_maximum_in_air_dried_coal)}
              </td>

            </tr>
            </tbody>
            </table>





      <div className="col-md-12 product-heading" id="ax.account_details">
          <h4 className="heading">SIZING, %
</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
          <tbody>
            <tr>
            <td id="td-right">
                <span>Above 50 mm Typical	</span>
                </td><td>
                {renderShipmentQty(data1.above_0_to_50mm_typical)}
              </td>
              <td id="td-right">
                <span>Under 2 mm Typical	</span>
                </td><td>
                {renderShipmentQty(data1.under_2mm_typical)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Above 50 mm Minimum		</span>
                </td><td>
                {renderShipmentQty(data1.above_0_to_50mm_min)}
              </td>
              <td id="td-right">
                <span>Under 2 mm Minimum		</span>
                </td><td>
                {renderShipmentQty(data1.under_2mm_min)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Above 50 mm Maximum		</span>
                </td><td>
                {renderShipmentQty(data1.above_0_to_50mm_max)}
              </td>
              <td id="td-right">
                <span>Under 2 mm Maximum		</span>
                </td><td>
                {renderShipmentQty(data1.under_2mm_max)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Size (0-50 mm) Typical		</span>
                </td><td>
                {renderShipmentQty(data1.size_0_to_50mm_typical)}
              </td>
              <td id="td-right">
                <span>Size Above 2 mm Typical		</span>
                </td><td>
                {renderShipmentQty(data1.size_above_2mm_typical)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Size (0-50 mm) Minimum		</span>
                </td><td>
                {renderShipmentQty(data1.size_0_to_50mm_min)}
              </td>
              <td id="td-right">
                <span>Size Above 2 mm Minimum		</span>
                </td><td>
                {renderShipmentQty(data1.size_above_2mm_min)}
              </td>

            </tr>
            <tr>
            <td id="td-right">
                <span>Size (0-50 mm) Maximum		</span>
                </td><td>
                {renderShipmentQty(data1.size_0_to_50mm_max)}
              </td>
              <td id="td-right">
                <span>Size Above 2 mm Maximum		</span>
                </td><td>
                {renderShipmentQty(data1.size_0_to_50mm_max)}
              </td>

            </tr>
            </tbody>
            </table>






            <div className="col-md-12 product-heading" id="ax.account_details">
          <h4 className="heading">Testing Method
</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
          <tbody>
            <tr>
            <td id="td-right">
                <span>Testing Method	</span>
                </td><td>
                {data1.testing_method}
              </td>
              <td id="td-right">
                <span>	</span>
                </td><td>

              </td>

            </tr>
            </tbody>
            </table>
            <div className="col-md-12 product-heading" id="ax.account_details">
          <h4 className="heading">System Information

</h4>
          <hr></hr>
        </div>
        <table class="table table-bordered account-table tables">
          <tbody>
            <tr>
            <td id="td-right">
                <span>Created By		</span>
                </td><td>
                {data1.created_by}
              </td>
              <td id="td-right">
                <span>	Last Modified By	</span>
                </td><td>
                {data1.last_modified_by}
              </td>

            </tr>
            </tbody>
            </table>
        <Row id="table-styles" className="product-table">
                  <div className="col-md-12 " id="head">
                    <h3 className="heading">Product History
                    </h3>
                    <hr></hr>
                  </div>
                  <table id="history" className="">
                    <thead>
                      <tr>
                        <th>Field Name</th>
                        <th>New Value</th>
                        <th>Old Value</th>
                        <th>Changed By</th>
                        <th>Change Time</th>
                      </tr>
                    </thead>

                    {history.length > 0 ? (
                      <tbody>
                        {history.map((y, i) => (
                          <tr>
                            <td>{y.field_name}</td>
                            <td>{y.new_value}</td>
                            <td>{y.old_value}</td>
                            <td>{y.changed_by}</td>
                            <td>{y.change_time}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td id="td-right"></td>
                          <td id="td-right"></td>
                          <td id="td-right">No data available</td>
                          <td id="td-right"></td>
                          <td id="td-right"></td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </Row>


                <Row id="table-styles" className="product-table">
                  <div className="col-md-12" id="head">
                    <h3 className="heading">PriceBook Information</h3>
                    <hr></hr>
                  </div>
                  <table id="price" className="">
                    <thead>
                      <tr>
                        <th>Price Book Name</th>
                        <th>Last Price</th>
                        <th>Use Standard Price	</th>
                        <th>Active</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                      <td></td><td></td><td>No available Data</td><td></td>
                      </tr>
                    </tbody>
                  </table>
                </Row>
                <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Quote Line Items
                              </span>

                            </h4>
                            <hr></hr>
                          </div>
                          <table id="historys" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>
                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Contract Products

                              </span>

                            </h4>
                            <hr></hr>
                          </div>
                          <table id="historys" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>
                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Trade Confirmation Forms


                              </span>

                            </h4>
                            <hr></hr>
                          </div>
                          <table id="tcf" className="">
                    <thead>
                      <tr>
                        <th>TCF No	</th>
                        <th>Contract Shipment	</th>
                        <th>Order	</th>
                        <th>Buyer</th>
                        <th>Supplier</th>
                        <th>Actual GAR (P)	</th>
                        <th>Actual GAR (S)		</th>
                        <th>Proposed Laycan To
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>No available Data</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      </tr>
                    </tbody>
                  </table>
                        </Row>
                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Order Products

                              </span>

                            </h4>
                            <hr></hr>
                          </div>
                          <table id="historys" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>
                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Buyer Products



                              </span>

                            </h4>
                            <hr></hr>
                          </div>
                          <table id="buyer" className="">
                    <thead>
                      <tr>
                        <th>Buyer Product Name	</th>
                        <th>Gross as received	</th>
                        <th>Order	</th>
                        <th>Ash ARB	</th>
                        <th>Moisture</th>
                        <th>Total Moisture
                        </th>

                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>No available Data</td>
                      <td></td>
                      <td></td>

                      </tr>
                    </tbody>
                  </table>
                        </Row>


                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Open Activities


                              </span>

                            </h4>
                            <hr></hr>
                          </div>
                          <table id="historys" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>

                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Activity History



                              </span>

                            </h4>
                            <hr></hr>
                          </div>
                          <table id="historys" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>
        <p></p><p></p>
            </div>
      )}
      </>
      </div></div>


    </div>
  );
};

export default ProductDetail;
